import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { debounceTime, fromEvent, merge, Observable, of, tap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ClassificationGroup } from 'src/modules/diversite/model/classification-group';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';
import { ContactLoadedEvent } from '../contact-search-profile/contact-search-profile.component';

@Component({
    selector: 'diversite-contact-simple-profile',
    templateUrl: './contact-simple-profile.component.html',
    styleUrl: './contact-simple-profile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSimpleProfileComponent {
    @Input() contactId: string;
    @Input() contact: Contact
    @Input() classificationId: string;
    @Input() deletable = false;

    @Output() removeContact = new EventEmitter<Contact>();
    @Output() viewProfile = new EventEmitter<Contact>();
    @Output() selectContact = new EventEmitter<Contact>();
    @Output() loaded = new EventEmitter<ContactLoadedEvent>();
    @Output() delete = new EventEmitter<string>();

    imageUrl: string;

    private clickEvents$: Observable<MouseEvent>;
    private disposeBag = new DisposeBag();

    contactSelection$: Observable<ClassificationGroup>;

    contact$: Observable<Contact>

    constructor(private classificationService: SearchClassificationService, private contactService: ContactService, private host: ElementRef, private chRef: ChangeDetectorRef) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.contactId?.currentValue && !this.contact$) {
            this.contact$ = this.contactService.contactById(changes.contactId?.currentValue).pipe(tap(contact => {
                this.contact = contact;
                this.onContactLoad(contact);
            }))
        }
        if (changes.contact?.currentValue && !this.contact$) {
            this.contact$ = of(changes.contact?.currentValue).pipe(tap(contact => {
                this.onContactLoad(contact);
            }));
        }
    }

    private onContactLoad(contact: Contact): void {
        this.imageUrl = contact.images[0]?.url;
        this.handleClickAndDoubleClick(contact);
        this.loaded.emit({
            contact,
            element: this.host
        });
        this.contactSelection$ = this.classificationService.classificationForContact(this.classificationId, this.contactId);
        setTimeout(() => {
            this.chRef.detectChanges();
        })
    }




    private handleClickAndDoubleClick(contact: Contact): void {
        if (!this.clickEvents$ && contact) {
            const clickEvent = fromEvent<MouseEvent>(this.host.nativeElement, "mouseup");
            const dblClickEvent = fromEvent<MouseEvent>(this.host.nativeElement, "dblclick");
            this.clickEvents$ = merge(clickEvent, dblClickEvent).pipe(debounceTime(250));
            this.clickEvents$.subscribe((event) => {
                if (event.type === "mouseup") {
                    this.select(contact);
                } else {
                    this.openProfile(contact);
                }
            }).disposedBy(this.disposeBag);
        }
    }

    private select(contact: Contact): void {
        this.selectContact.emit(contact);
    }

    private openProfile(contact: Contact): void {
        this.viewProfile.emit(contact)
    }

    onDeleteClick(): void {
        this.delete.emit(this.contactId)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
