import { ContactResult } from "../services/elasticsearch.service";
import { MetaTotal } from "./search-meta";

export class SearchResults {
    private _total: MetaTotal;
    private _contactResults: ContactResult[];
    private _groupBy: SearchResultsGroupBy;
    private _groups: SearchResultsGroup[];

    constructor(builder?: { total?: MetaTotal; contactResults: ContactResult[], groupBy: SearchResultsGroupBy }) {
        this._total = builder?.total || { value: 0, relation: "" };
        this._groupBy = builder?.groupBy || "no-grouping";
        this._groups = this.groupsBuild(builder?.contactResults || [])
    }

    get total(): MetaTotal { return this._total; }
    get groupBy(): SearchResultsGroupBy {
        return this._groupBy;
    }
    get groups(): SearchResultsGroup[] {
        return this._groups;
    }

    private groupsBuild(contactResults: ContactResult[]): SearchResultsGroup[] {
        if (this.groupBy === "no-grouping") {
            return this.groupsForNoGroupingStrategy(contactResults)
        }
        if (this.groupBy === "week") {
            return this.groupsForWeekGroupingStrategy(contactResults);
        }
        if (this.groupBy === "day") {
            return this.groupsForDayGroupingStrategy(contactResults);
        }
        if (this.groupBy === "hour") {
            return this.groupsForHourGroupingStrategy(contactResults);
        }
        return [];
    }

    private groupsForNoGroupingStrategy(contactResults: ContactResult[]): SearchResultsGroup[] {
        return [new SearchResultsGroup({ id: "no-grouping-group", contactResults })]
    }

    private groupsForWeekGroupingStrategy(contactResults: ContactResult[]): SearchResultsGroup[] {
        const groupedContactsByWeekNumber = {};

        contactResults.filter(cr => cr.contact ? true : false).forEach(cResult => {
            const lastUpdated = new Date(cResult.contact.lastUpdate ? cResult.contact.lastUpdate : cResult.contact.createdAt);
            const weekYearKey = this.getWeekYearKey(lastUpdated);
            if (!groupedContactsByWeekNumber[weekYearKey]) {
                groupedContactsByWeekNumber[weekYearKey] = {
                    name: this.getWeekRange(lastUpdated),
                    contactResults: []
                };
            }
            groupedContactsByWeekNumber[weekYearKey].contactResults.push(cResult);
        });

        return Object.keys(groupedContactsByWeekNumber).map(yearWeekKey => {
            return new SearchResultsGroup({
                id: yearWeekKey,
                title: groupedContactsByWeekNumber[yearWeekKey].name,
                contactResults: groupedContactsByWeekNumber[yearWeekKey].contactResults
            })
        })

        // return groupedContacts;
    }

    private getWeekRange(date: Date): string {
        // Assuming Monday as the start of the week
        const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay() || 7) + 1);
        const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

        return `${this.formatWeekDateString(startOfWeek)} - ${this.formatWeekDateString(endOfWeek)}`;
    }

    private formatWeekDateString(date: Date): string {
        return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;
    }

    private getWeekYearKey(date: Date): string {
        const year = date.getFullYear();
        const weekNumber = this.getWeekNumber(date);
        return `${year}-${weekNumber}`;
    }

    private getWeekNumber(date: Date): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const diff = Math.round((date.getTime() - firstDayOfYear.getTime()) / 86400000);
        return Math.ceil((diff + firstDayOfYear.getDay() + 1) / 7);
    }

    private groupsForDayGroupingStrategy(contactResults: ContactResult[]): SearchResultsGroup[] {
        const groupedContactsByDay = {};

        contactResults.filter(cr => cr.contact ? true : false).forEach(cResult => {
            const lastUpdated = new Date(cResult.contact.lastUpdate ? cResult.contact.lastUpdate : cResult.contact.createdAt);
            const dayYearKey = this.getDayYearKey(lastUpdated);
            if (!groupedContactsByDay[dayYearKey]) {
                groupedContactsByDay[dayYearKey] = {
                    name: this.formatDayDateString(lastUpdated),
                    contactResults: [],
                };
            }
            groupedContactsByDay[dayYearKey].contactResults.push(cResult);
        });

        return Object.keys(groupedContactsByDay).map(dayKey => {
            return new SearchResultsGroup({
                id: dayKey,
                title: groupedContactsByDay[dayKey].name,
                contactResults: groupedContactsByDay[dayKey].contactResults
            })
        })
    }

    private getDayYearKey(date: Date): string {
        const year = date.getFullYear();
        const day = this.formatDayDateString(date);
        return `${year}-${day}`;
    }

    private formatDayDateString(date: Date): string {
        return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;
    }

    private groupsForHourGroupingStrategy(contactResults: ContactResult[]): SearchResultsGroup[] {
        const groupedContactsByHour = {};

        contactResults.filter(cr => cr.contact ? true : false).forEach(cResult => {
            const lastUpdated = new Date(cResult.contact.lastUpdate ? cResult.contact.lastUpdate : cResult.contact.createdAt);
            const hourDayKey = this.getHourDayKey(lastUpdated);
            if (!groupedContactsByHour[hourDayKey]) {
                groupedContactsByHour[hourDayKey] = {
                    name: this.formatHourDateString(lastUpdated),
                    contactResults: [],
                };
            }
            groupedContactsByHour[hourDayKey].contactResults.push(cResult);
        });

        return Object.keys(groupedContactsByHour).map(dayKey => {
            return new SearchResultsGroup({
                id: dayKey,
                title: groupedContactsByHour[dayKey].name,
                contactResults: groupedContactsByHour[dayKey].contactResults
            })
        })

    }

    private getHourDayKey(date: Date): string {
        const day = this.formatHourDateString(date);
        const hour = this.getHourString(date);
        return `${day}-${hour}`;
    }

    private getHourString(date: Date): string {
        return date.toLocaleString('en-US', { hour: 'numeric', hour12: false });
    }

    private formatHourDateString(date: Date): string {
        return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear(), date.toLocaleString('en-US', {
            hour: 'numeric',
            hour12: false
        })}:00`;
    }

    change(builder: { total?: MetaTotal; contactResults?: ContactResult[] }) {
        return new SearchResults({
            total: this._total,
            groupBy: this._groupBy,
            contactResults: this._contactResults,
            ...builder
        })
    }
}

export type SearchResultsGroupBy = "no-grouping" | "hour" | "day" | "week"

export class SearchResultsGroup {
    private _id: string;
    private _title: string;
    private _contactResults: ContactResult[];

    constructor(builder: { id?: string, title?: string, contactResults: ContactResult[] }) {
        this._id = builder.id || null;
        this._title = builder.title || null;
        this._contactResults = builder.contactResults || [];
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get contactResults(): ContactResult[] {
        return this._contactResults;
    }

    change(builder: { title: string, contactResults: ContactResult[] }): SearchResultsGroup {
        return new SearchResultsGroup({
            title: this._title,
            contactResults: this._contactResults,
            ...builder,
            id: this._id
        })
    }

}