import { Contact } from "./contact";

export class StatisticSubCategory {
    private _id: string;
    private _name: string; // Noir, Asiatique, Caucasien
    private _filterContactStrategy: (contacts: Contact[]) => Contact[]

    static get DEFAULT(): StatisticSubCategory {
        return new StatisticSubCategory({
            id: "non-classified",
            name: "Non-classifié"
        })
    }

    constructor(builder: { id: string, name: string, filterContactStrategy?: (contacts: Contact[]) => Contact[] }) {
        this._id = builder.id;
        this._name = builder.name;
        this._filterContactStrategy = builder.filterContactStrategy ? builder.filterContactStrategy : null;
    }

    get id(): string { return this._id; }
    get name(): string { return this._name; }

    filterElligibleContacts(contacts: Contact[]): Contact[] {
        return this._filterContactStrategy ? this._filterContactStrategy(contacts) : contacts;
    }
}