import { guid, randomColor } from "src/app/core/functions";

export interface ClassificationGroupBuilder {
    id?: string;
    name?: string;
    contactIds?: string[];
    groups?: ClassificationGroup[];
    color?: string;
}
export class ClassificationGroup {
    private _id: string;
    private _name: string;
    private _contactIds: string[];
    private _groups: ClassificationGroup[];
    private _color: string;

    static get DEFAULT(): ClassificationGroup {
        return new ClassificationGroup({
            name: "Préselection",
            contactIds: [],
            groups: [],
            color: "#ff0000"
        });
    }

    constructor(builder: ClassificationGroupBuilder) {
        this._id = builder.id || guid();
        this._name = builder.name || "Nom du groupe";
        this._contactIds = builder.contactIds || [];
        this._groups = builder.groups || [];
        this._color = builder.color || randomColor();
    }

    get id(): string { return this._id; }
    get name(): string { return this._name; }
    get contactIds(): string[] { return this._contactIds; }
    get groups(): ClassificationGroup[] { return this._groups; }
    get color(): string { return this._color; }


    toggleContact(contactId: string): ClassificationGroup {
        const contactIds: string[] = this.contactIds.includes(contactId) ? this.contactIds.filter(cId => cId !== contactId) : [...this.contactIds, contactId];
        return this.change({ contactIds });
    }

    editName(name: string): ClassificationGroup {
        return this.change({ name });
    }

    editColor(color: string): ClassificationGroup {
        return this.change({ color });
    }

    addChildGroup(): ClassificationGroup {
        return this.change({
            groups: [...this.groups, new ClassificationGroup({})]
        })
    }

    changeChildGroup(group: ClassificationGroup): ClassificationGroup {
        return this.change({ groups: this.groups.map(g => g.id === group.id ? group : g) });
    }

    hasSelection(): boolean {
        return this.contactIds.length > 0 || this.groups.find(g => g.hasSelection()) ? true : false;
    }

    hasContact(contactId: string): boolean {
        return this.contactIds.includes(contactId) ? true : false;
    }

    findChildGroup(contactId: string): ClassificationGroup {

        if (this.hasContact(contactId)) {
            return this.change({});
        }

        for (const child of this.groups) {
            const foundNode = child.findChildGroup(contactId);
            if (foundNode) {
                return foundNode;
            }
        }

    }

    group(groupId: string): ClassificationGroup {
        if (this.id === groupId) {
            return this.change({});
        }

        for (const child of this.groups) {
            const foundNode = child.group(groupId);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    removeChildGroup(groupId: string): ClassificationGroup {
        return this.change({
            groups: this.groups.filter(g => g.id !== groupId)
        })
    }

    setContacts(contactIds: string[]): ClassificationGroup {
        return this.change({ contactIds: [...contactIds] })
    }

    private change(builder: ClassificationGroupBuilder): ClassificationGroup {
        return new ClassificationGroup({
            id: this._id,
            name: this._name,
            contactIds: this._contactIds,
            groups: this._groups,
            color: this._color,
            ...builder
        })
    }
}