<as-split unit="pixel" (dragEnd)="onDragEndSections($event)">
    <as-split-area [size]="isSearchParametersClosed ? 30 : areaSizes[0]">
        <div class="search-params-container">
            <diversite-search-parameters
                [hidden]="isSearchParametersClosed"
                [projectId]="projectId"
                [contextNodeId]="contextNodeId"
                [favoriteNodes]="favoriteSearchNodes"
                [searchMeta]="{ total: searchResults?.total ? searchResults?.total : 0 }"
                [tabOpen]="tabOpen"
                [searchDefinition]="searchDefinition"
                (search)="onSearch($event)"
                (changeTab)="onChangeTab($event)"
            />
            <div *ngIf="isSearchParametersClosed" (click)="openSearchParams()" class="search-params-toggle">
                <cds-icon shape="angle" direction="right"></cds-icon>
            </div>
            <div *ngIf="!isSearchParametersClosed" (click)="closeSearchParams()" class="search-params-toggle">
                <cds-icon shape="angle" direction="left"></cds-icon>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="*">
        <div class="contacts-results">
            <div class="menu-up" [class.open]="detailedSearchMetaOpen">
                <div *ngIf="searchResults && detailedSearchMetaOpen" class="detailed-section">
                    <div>
                        <button
                            class="btn btn-sm button-menu"
                            [disabled]="searchResults?.total.value >= 10000"
                            (click)="onMassEditResults(searchDefinition)"
                        >
                            Modifier les membres
                        </button>
                        <button
                            [disabled]="searchResults?.total.value > 500"
                            class="btn btn-sm button-menu"
                            (click)="onCreateReport()"
                        >
                            Créer un rapport
                        </button>
                        <button
                            [disabled]="searchResults?.total.value >= 5000"
                            class="btn btn-sm button-menu"
                            (click)="onExport()"
                        >
                            Exporter
                        </button>
                        <button
                            [disabled]="searchResults?.total.value >= 2000"
                            class="btn btn-sm button-menu"
                            (click)="onCreateFolder()"
                        >
                            Créer un dossier
                        </button>
                    </div>
                    <div class="top">
                        <div *ngIf="searchResults && searchResults.total" class="total-info">
                            <small>{{ searchResults.total.value }}</small>
                        </div>

                        <clr-range-container>
                            <label>Grandeur des cartes</label>
                            <input
                                type="range"
                                (change)="onImageWidthChange($event.target.value)"
                                [(ngModel)]="contactWidth"
                                clrRange
                                min="80"
                                max="500"
                            />
                        </clr-range-container>

                        <clr-range-container>
                            <label>Grandeur des infos</label>
                            <input type="range" [(ngModel)]="infoSize" clrRange min="35" max="500" />
                        </clr-range-container>
                    </div>

                    <div class="bottom">
                        <diversite-attribute-multiselect
                            [label]="'Champs à afficher'"
                            [selection]="shownAttributesSelection"
                            (selectionChange)="onComboboxSelectionChange($event)"
                        />
                        <diversite-search-parameters-compact [searchDefinition]="searchDefinition" />
                    </div>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox value="option1" name="options1" [(ngModel)]="hideSelected" />
                        <label>Caché les membres classifiés</label>
                    </clr-checkbox-wrapper>
                </div>

                <div
                    *ngIf="searchResults"
                    class="toggle-details"
                    (click)="detailedSearchMetaOpen = !detailedSearchMetaOpen"
                >
                    <div *ngIf="!detailedSearchMetaOpen && searchResults && searchResults.total" class="total-info">
                        <small>{{ searchResults.total.value }}</small>
                    </div>
                    <cds-icon
                        size="14"
                        shape="angle-double"
                        [direction]="detailedSearchMetaOpen ? 'up' : 'down'"
                    ></cds-icon>
                </div>
            </div>
            <div
                class="layout-change"
                [class.selection-bottom]="selectionPosition === 'bottom'"
                [class.selection-top]="selectionPosition === 'top'"
                [class.selection-left]="selectionPosition === 'left'"
                [class.selection-right]="selectionPosition === 'right'"
            >
                <div class="contacts" [class.hide-selected]="hideSelected">
                    <ng-container *ngIf="searchResults">
                        <ng-container *ngIf="searchResultsNew.total.value > 0">
                            <div class="bookmark">
                                <small
                                    ><strong>{{ searchResultsNew.total.value }}</strong> nouveaux depuis la derniere
                                    recherche</small
                                >
                                <div class="btn-group btn-sm">
                                    <div (click)="changeGroup('no-grouping')" class="radio btn">
                                        <input type="radio" name="new-grouping" [checked]="groupBy === 'no-grouping'" />
                                        <label>Sans groupe</label>
                                    </div>
                                    <div (click)="changeGroup('hour')" class="radio btn">
                                        <input type="radio" name="new-grouping" [checked]="groupBy === 'hour'" />
                                        <label>Heure</label>
                                    </div>
                                    <div (click)="changeGroup('day')" class="radio btn">
                                        <input type="radio" name="new-grouping" [checked]="groupBy === 'day'" />
                                        <label>Jour</label>
                                    </div>
                                    <div (click)="changeGroup('week')" class="radio btn">
                                        <input type="radio" name="new-grouping" [checked]="groupBy === 'week'" />
                                        <label>Semaine</label>
                                    </div>
                                </div>
                            </div>
                            <div class="group" *ngFor="let group of searchResultsNew.groups; trackBy: trackByGroup">
                                <div *ngIf="group.title" class="group-title">
                                    {{ group.title }} - {{ group.contactResults.length }} membres
                                </div>
                                <diversite-contact-search-profile
                                    *ngFor="let contactData of group.contactResults; trackBy: trackByContactResult"
                                    [attr.data-contactid]="contactData.contactId"
                                    [classificationId]="searchDefinition.id"
                                    [contact]="contactData.contact"
                                    [highlights]="contactData.highlights"
                                    [height]="contactHeight(contactData.highlights)"
                                    [width]="contactWidth"
                                    [infoSize]="infoSize"
                                    (loaded)="onContactLoaded($event)"
                                    (selectContact)="toggleSelection($event)"
                                    (viewProfile)="onViewProfile($event)"
                                    (editContact)="onEditContact($event)"
                                    (openFormPane)="onOpenFormPane($event, contactData.contactId)"
                                ></diversite-contact-search-profile>
                            </div>
                        </ng-container>
                        <div class="bookmark">
                            <small
                                >Dernière recherche :
                                {{ searchDefinition?.bookmarkSpec.bookmark.date | date : "dd-MM-yyyy - HH:mm" }}</small
                            >
                            <div class="btn-group btn-sm">
                                <div (click)="changeGroup('no-grouping')" class="radio btn">
                                    <input type="radio" name="grouping" [checked]="groupBy === 'no-grouping'" />
                                    <label>Sans groupe</label>
                                </div>
                                <div (click)="changeGroup('hour')" class="radio btn">
                                    <input type="radio" name="grouping" [checked]="groupBy === 'hour'" />
                                    <label>Heure</label>
                                </div>
                                <div (click)="changeGroup('day')" class="radio btn">
                                    <input type="radio" name="grouping" [checked]="groupBy === 'day'" />
                                    <label>Jour</label>
                                </div>
                                <div (click)="changeGroup('week')" class="radio btn">
                                    <input type="radio" name="grouping" [checked]="groupBy === 'week'" />
                                    <label>Semaine</label>
                                </div>
                            </div>
                        </div>

                        <div class="group" *ngFor="let group of searchResults.groups; trackBy: trackByGroup">
                            <div *ngIf="group.title" class="group-title">
                                {{ group.title }} - {{ group.contactResults.length }} membres
                            </div>
                            <diversite-contact-search-profile
                                *ngFor="let contactData of group.contactResults; trackBy: trackByContactResult"
                                [attr.data-contactid]="contactData.contactId"
                                [classificationId]="searchDefinition.id"
                                [contact]="contactData.contact"
                                [highlights]="contactData.highlights"
                                [height]="contactHeight(contactData.highlights)"
                                [width]="contactWidth"
                                [infoSize]="infoSize"
                                (loaded)="onContactLoaded($event)"
                                (selectContact)="toggleSelection($event)"
                                (viewProfile)="onViewProfile($event)"
                                (editContact)="onEditContact($event)"
                                (openFormPane)="onOpenFormPane($event, contactData.contactId)"
                            ></diversite-contact-search-profile>
                        </div>
                    </ng-container>
                    <div #loadingContacts class="loading-contacts">
                        <clr-spinner [hidden]="!loading"></clr-spinner>
                    </div>
                    <!-- <div class="no-results" [hidden]="loading || (searchResults && searchResults.total.value > 0)">
                        <clr-icon size="50" shape="sad-face"></clr-icon>
                        <em>Aucun résultat(s)</em>
                    </div> -->
                </div>

                <ng-container *ngIf="classification$ | async as classification">
                    <diversite-classification-widget
                        *ngIf="classification.hasSelection() && !(isClassificationPaneOpen$ | async)"
                        [classification]="classification"
                        [pane]="pane"
                        (changePosition)="onChangeSelectionPosition($event)"
                    />
                </ng-container>
            </div>
        </div>
    </as-split-area>
</as-split>
