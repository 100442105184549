import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { ClrCombobox } from "@clr/angular";
import { forkJoin, Observable } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { NotificationService } from "src/modules/core/services/notification.service";
import { FormElementGenericOptions } from "src/modules/fillout/components/form-element/form-element.component";
import { ResponseInput } from "src/modules/fillout/model/response-input";

import { Contact } from "../../model/contact";
import { FormElement, FormElementInput } from "../../model/form/form-element/form-element";
import { FormModule } from "../../model/form/form-module";
import { SearchDefinition } from "../../model/search-definition";
import { ContactService } from "../../services/contact.service";
import { ContactsDatabase, ContactsDatabasesService } from "../../services/contacts-databases.service";
import { ModuleService } from "../../services/module.service";
import { SearchContextService } from "../../services/search-context.service";
import { MassEditContactsSystemJob } from "../../services/system-jobs.service";
import { TranslateService } from "../../services/translate.service";

@Component({
    selector: "diversite-contacts-mass-edit",
    templateUrl: "./contacts-mass-edit.component.html",
    styleUrls: ["./contacts-mass-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsMassEditComponent implements OnInit, OnChanges {
    constructor(
        private translateService: TranslateService,
        private contactService: ContactService,
        private searchContextService: SearchContextService,
        private modulesService: ModuleService,
        private notificationService: NotificationService,
        private contactsDatabaseService: ContactsDatabasesService
    ) { }

    @Input() contactIds: string[];
    @Input() datasource: string;
    @Input() searchDefinitionId: string;

    @Output() close = new EventEmitter<void>();

    @ViewChild("attributeCombobox") attributeCombobox: ClrCombobox<any>;
    readonly optionsForFormElements: FormElementGenericOptions = {
        showLabel: false,
    };

    attributeModules$: Observable<FormModule[]>;
    attrs$: Observable<AttributeCombobox[]>;
    contacts$: Observable<Contact[]>;
    datasource$: Observable<ContactsDatabase>;
    searchDefinition$: Observable<SearchDefinition>;
    private searchDefinition: SearchDefinition;
    selection: any = [];
    editMode: EditMode = "edit-all-contact-at-once";
    currentStep: Step = "select-attributes";
    toEditFormElements: FormElement[] = [];
    changes = new Map<string, AttributeChange>();

    private contactIdFigs: string[];

    ngOnInit(): void {
        this.attributeModules$ = this.modulesService.templateModules();
        this.attrs$ = this.attributeModules$.pipe(
            map((modules) => {
                return modules
                    .map((m) =>
                        m.activeFormElements
                            .filter((fe) => fe.isInput)
                            .map((fe) => fe as FormElementInput)
                            .map((formElement) => {
                                return { id: formElement.name, label: formElement.label[this.lang] };
                            })
                    )
                    .reduce((acc, val) => acc.concat(val), []);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactIds && changes.contactIds.currentValue) {
            this.contacts$ = (
                forkJoin(
                    changes.contactIds.currentValue.map((id) => {
                        return this.contactService.contactById(id).pipe(first());
                    })
                ) as Observable<Contact[]>
            ).pipe(
                tap((contacts) => {
                    this.contactIdFigs = contacts.map((c) => {
                        return c.idfig;
                    });
                })
            );
        }
        if (changes.datasource && changes.datasource.currentValue) {
            this.datasource$ = this.contactsDatabaseService.databaseByDatasourceIndex(changes.datasource.currentValue);
        }
        if (changes.searchDefinitionId && changes.searchDefinitionId.currentValue) {
            this.searchDefinition$ = this.searchContextService
                .searchDefinition(changes.searchDefinitionId.currentValue, { listen: false })
                .pipe(
                    tap((searchDef) => {
                        this.searchDefinition = searchDef;
                    })
                );
        }
    }

    labelForIndex(index: string): string {
        return this.modulesService.labelForIndex(index, this.lang);
    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }
    onAttributeCheckboxChange(index: string): void {
        const exists = this.selection.find((i) => index === i);
        if (exists) {
            this.selection = this.selection.filter((i) => index !== i);
        } else {
            this.selection = [...this.selection, index];
        }
    }

    onComboboxOpenChange(event: any): void {
        // console.log("onComboboxOpenChange", event, this.attributeCombobox);
    }

    onComboboxInputChange(event: any): void {
        // console.log("onComboboxInputChange", event, this.attributeCombobox);
    }

    onComboboxSelectionChange(event: any): void {
        // console.log("onComboboxSelectionChange", event, this.attributeCombobox);
        this.attributeCombobox.textbox.nativeElement.value = "";
    }

    isAttributeChecked(index: string): boolean {
        return this.selection.find((i) => index === i) ? true : false;
    }

    get isNextDisabled(): boolean {
        if (this.isStepOne) {
            return !this.selection || this.selection.length === 0;
        } else if (this.isStepTwo) {
            return false;
        }
        return false;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    get isStepOne(): boolean {
        return this.currentStep === "select-attributes";
    }
    get isStepTwo(): boolean {
        return this.currentStep === "edit-attributes";
    }

    get isLoading(): boolean {
        return this.currentStep === "loading";
    }

    goToStep(step: Step): void {
        if (step === "edit-attributes") {
            this.toEditFormElements = this.selection.map((index) => {
                return this.modulesService.formElementForIndex(index);
            });
            this.changes.clear();
        }
        this.currentStep = step;
    }

    onAttributeChange(response: ResponseInput, contact?: Contact): void {
        if (this.changes.has(response.input.name)) {
            this.changes.set(response.input.name, {
                ...this.changes.get(response.input.name),
                value: response.value,
            });
        } else {
            this.changes.set(response.input.name, {
                attribute: response.input.name,
                value: response.value,
                type: "set",
            });
        }
    }
    onConcatChange(isConcat: boolean, attributeName: string): void {
        if (this.changes.has(attributeName)) {
            this.changes.set(attributeName, { ...this.changes.get(attributeName), type: isConcat ? "concat" : "set" });
        } else {
            this.changes.set(attributeName, { attribute: attributeName, value: "", type: isConcat ? "concat" : "set" });
        }
    }

    onCancel(): void {
        this.close.emit();
    }

    editContacts(): void {
        this.goToStep("loading");
        let obs;
        if (this.contactIds) {
            obs = this.contactService.massEditContacts(
                this.contactIdFigs,
                [...this.changes].map((t) => t[1])
            );
        } else if (this.searchDefinitionId) {
            obs = this.contactService.massEditSearchDefinitionResults(
                this.searchDefinition,
                [...this.changes].map((t) => t[1])
            );
        } else {
            obs = this.contactService.massEditDatasource(
                this.datasource,
                [...this.changes].map((t) => t[1])
            );
        }
        this.notificationService.show(`Processus d'édidion de masse sur le point de débuter...`, "info", {
            autoClose: false,
        });
        obs.subscribe((response: MassEditContactsSystemJob) => {
            if (response.percentageDone === 100) {
                this.notificationService.show(`Processus d'édidion de masse. ${response.percentageDone} %`, "success", {
                    autoClose: true,
                });
            } else {
                this.notificationService.show(`Processus d'édidion de masse. ...${response.percentageDone} %`, "info", {
                    autoClose: false,
                });
                this.close.emit();
            }
        });
    }
}

export interface AttributeCombobox {
    id: string;
    label: string;
}

export type Step = "select-attributes" | "edit-attributes" | "loading";
export type EditMode = "edit-all-contact-at-once" | "edit-individually";
export interface AttributeChange {
    attribute: string;
    type: AttributeChangeType;
    value: any;
}
export type AttributeChangeType = "set" | "concat";
