<div *ngIf="isLogged" class="info-options">
    <clr-select-container>
        <select
            (change)="onPerspectiveProjectChange($event.target.value)"
            clrSelect
            name="attribute-perspective"
            [(ngModel)]="selectedProject"
        >
            <option value="">Perspective de projet</option>
            <option [value]="project.id" *ngFor="let project of projects$ | async; trackBy: trackById">
                {{ project.name }}
            </option>
        </select>
    </clr-select-container>

    <clr-select-container>
        <select
            (change)="onPerspectiveFormChange($event.target.value)"
            clrSelect
            name="attribute-perspective"
            [(ngModel)]="selectedForm"
            [disabled]="selectedProject === ''"
        >
            <option value="">Perspective de formulaire</option>
            <option [value]="form.id" *ngFor="let form of forms$ | async; trackBy: trackById">
                {{ form.adminName }}
            </option>
        </select>
    </clr-select-container>

    <!-- <clr-toggle-wrapper>
        <input type="checkbox" clrToggle name="edit-perspective-mode" [(ngModel)]="editPerspectiveMode" />
        <label>Mode d'edition de perspective</label>
    </clr-toggle-wrapper> -->

    <div class="right">
        <a *ngIf="editPerspectiveMode" class="action-link reset-attributes" (click)="resetAttributeNode()"
            >Réinitialiser l'arbre d'attributs</a
        >
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="show-empty-attributes" [(ngModel)]="showEmptyAttrs" />
            <label>Afficher les champs vide</label>
        </clr-checkbox-wrapper>
    </div>
</div>

<ng-container>
    <div *ngIf="$attributeNodes | async as attributeNodes" class="attribute-nodes">
        <diversite-attribute-node
            *ngFor="let attributeNode of attributeNodes; trackBy: trackById"
            [hidden]="!(showEmptyAttrs || hasValue(attributeNode))"
            [attr.data-nodeid]="attributeNode.id"
            [showEmptyAttributes]="showEmptyAttrs"
            [contact]="contact"
            [editPerspectiveMode]="editPerspectiveMode"
            [attributeNode]="attributeNode"
            (sortTree)="onSortTree()"
            (attributeNodeChange)="onAttributeNodeChange($event)"
            (removeNode)="onRemoveNode($event)"
        ></diversite-attribute-node>
    </div>
</ng-container>

<a class="add-sub-folder" *ngIf="editPerspectiveMode" (click)="addFolder()" class="action-link add-folder"
    >Ajouter un dossier</a
>
