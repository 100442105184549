import { Injectable } from '@angular/core';
import { Contact } from '../model/contact';

@Injectable({
    providedIn: 'root'
})
export class StatisticsFilterContactStrategyService {

    private _strategies = new Map<string, (contacts: Contact[]) => Contact[]>();

    constructor() {
        this._strategies.set("m", (contacts: Contact[]) => { return this.filterMaleContacts(contacts) });
        this._strategies.set("f", (contacts: Contact[]) => { return this.filterFemaleContacts(contacts) })
        this._strategies.set("x", (contacts: Contact[]) => { return this.filterOtherGenderContacts(contacts) })
        this._strategies.set("c", (contacts: Contact[]) => { return this.filterCaucasianContacts(contacts) })
        this._strategies.set("n", (contacts: Contact[]) => { return this.filterBlackSkinContacts(contacts) })
        this._strategies.set("a", (contacts: Contact[]) => { return this.filterAsianContacts(contacts) })
        this._strategies.set("other-race", (contacts: Contact[]) => { return this.filterOtherRaceContacts(contacts) })
    }

    filterStrategyForSubCategory(subCategoryId: string): (contacts: Contact[]) => Contact[] {
        const strat = this._strategies.get(subCategoryId)
        if (!strat) {
            console.warn(`Need to implement filter contacts strategy for id : ${subCategoryId}`)
        }
        return strat ? strat : (contacts: Contact[]) => { return contacts };
    }


    private filterMaleContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => c.sex?.toLowerCase() === "m");
        }

        return [];
    }
    private filterFemaleContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => c.sex?.toLowerCase() === "f");
        }
        return [];
    }
    private filterOtherGenderContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => {
                const sex = c.sex?.toLowerCase();
                return sex !== "f" && sex !== "m"
            });
        }
        return [];
    }
    private filterCaucasianContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => this.isCaucasian(c));
        }
        return [];
    }
    private filterBlackSkinContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => this.isBlackSkin(c));
        }
        return [];
    }
    private filterAsianContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => this.isAsian(c));
        }
        return [];
    }
    private filterOtherRaceContacts(contacts: Contact[]): Contact[] {
        if (contacts) {
            return contacts.filter(c => (!this.isAsian(c) && !this.isBlackSkin(c) && !this.isCaucasian(c)));
        }
        return [];
    }


    private isCaucasian(c: Contact): boolean {
        const value: string = c.getAttributeValue("groupe_ethnique");
        if (value) {
            return value.toLowerCase().startsWith("cauca")
        }
        return false;
    }
    private isBlackSkin(c: Contact): boolean {
        const value: string = c.getAttributeValue("groupe_ethnique");
        if (value) {
            return value.toLowerCase().startsWith("noir")

        }
        return false;
    }
    private isAsian(c: Contact): boolean {
        const value: string = c.getAttributeValue("groupe_ethnique");
        if (value) {
            return value.toLowerCase().startsWith("asia")

        }
        return false;
    }
}

