import { ClassificationGroup } from "./classification-group";

export interface ClassificationBuilder {
    id?: string;
    defaultGroup?: ClassificationGroup;
    groups?: ClassificationGroup[];
}

export class Classification {
    private _id: string;
    private _defaultGroup: ClassificationGroup;
    private _groups: ClassificationGroup[];

    constructor(builder: ClassificationBuilder) {
        this._id = builder.id;
        this._defaultGroup = builder.defaultGroup || ClassificationGroup.DEFAULT;
        this._groups = builder.groups || [];
    }

    get id(): string { return this._id; }
    get defaultGroup(): ClassificationGroup { return this._defaultGroup; }
    get groups(): ClassificationGroup[] { return this._groups; }

    toggleContact(contactId: string, classificationGroupId?: string): Classification {
        if (classificationGroupId) {
            return this.change({
                groups: this.groups.map(g => g.id === classificationGroupId ? g.toggleContact(contactId) : g)
            })
        }
        return this.change({
            defaultGroup: this.defaultGroup.toggleContact(contactId)
        })
    }

    addGroup(): Classification {
        return this.change({
            groups: [...this.groups, new ClassificationGroup({})]
        })
    }

    changeDefaultGroup(group: ClassificationGroup): Classification {
        return this.change({ defaultGroup: group });
    }

    changeChildGroup(group: ClassificationGroup): Classification {
        return this.change({
            groups: this.groups.map(g => g.id === group.id ? group : g)
        })
    }

    removeChildGroup(groupId: string): Classification {
        return this.change({
            groups: this.groups.filter(g => g.id !== groupId)
        })
    }

    group(groupId: string): ClassificationGroup {
        let foundedGroup;

        const foundDefaultG = this.defaultGroup.group(groupId);
        if (foundDefaultG) {
            foundedGroup = foundDefaultG;
        }


        this.groups.forEach(g => {
            const foundG = g.group(groupId);
            if (foundG) {
                foundedGroup = foundG;
            }
        })


        return foundedGroup;
    }

    groupForContact(contactId: string): ClassificationGroup {
        let foundedGroup;

        const foundDefaultG = this.defaultGroup.findChildGroup(contactId);
        if (foundDefaultG) {
            foundedGroup = foundDefaultG;
        }


        this.groups.forEach(g => {
            const foundG = g.findChildGroup(contactId);
            if (foundG) {
                foundedGroup = foundG;
            }
        })


        return foundedGroup;
    }

    hasSelection(): boolean {
        return this.defaultGroup.hasSelection() || this.groups.find(g => g.hasSelection()) ? true : false;
    }

    private change(builder: ClassificationBuilder): Classification {
        return new Classification({
            id: this._id,
            defaultGroup: this._defaultGroup,
            groups: this._groups,
            ...builder
        })
    }
}