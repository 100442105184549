<div class="classification">
    <div class="top">
        <small
            ><span class="color-pin" [style.backgroundColor]="classification.defaultGroup.color"></span
            >{{ classification.defaultGroup.name }}</small
        >
        <div class="options">
            <clr-range-container [clrRangeHasProgress]="true">
                <input type="range" clrRange min="80" max="300" [(ngModel)]="faceSize" />
            </clr-range-container>
            <cds-icon (click)="onChangePosition('top')" shape="angle" direction="up" />
            <cds-icon (click)="onChangePosition('bottom')" shape="angle" direction="down" />
            <cds-icon (click)="onChangePosition('left')" shape="angle" direction="left" />
            <cds-icon (click)="onChangePosition('right')" shape="angle" direction="right" />
            <cds-icon shape="pop-out" (click)="openClassificationPane()"></cds-icon>
        </div>
    </div>
    <div class="contacts">
        <diversite-contact-simple-profile
            [style.width.px]="faceSize"
            [style.height.px]="faceSize"
            *ngFor="let contactId of classification.defaultGroup.contactIds; trackBy: trackById"
            [contactId]="contactId"
            [deletable]="true"
            (delete)="onDelete($event)"
        ></diversite-contact-simple-profile>
    </div>
</div>
