import { PaneContextData } from '../components/dashboard/services/pane.service';

export interface PaneBuilder {
    id?: string;
    type?: PaneType;
    locked?: boolean;
    contextNodeId?: string;
    projectId?: string;
    contextData?: PaneContextData;
}
export class Pane {
    private _id: string;
    private _type: PaneType;
    private _locked: boolean;
    private _projectId: string;
    private _contextNodeId: string;
    private _contextData: PaneContextData;

    static DEFAULT_PANE = new Pane({ type: "default", projectId: null });

    constructor(builder: PaneBuilder) {
        this._id = builder.id;
        this._type = builder.type || "default";
        this._locked = builder.locked === true ? true : false;
        this._projectId = builder.projectId || null;
        this._contextNodeId = builder.contextNodeId || null;
        this._contextData = builder.contextData || null;
    }

    get id(): string {
        return this._id;
    }
    get type(): PaneType {
        return this._type;
    }
    get projectId(): string {
        return this._projectId;
    }
    get contextNodeId(): string {
        return this._contextNodeId;
    }
    get contextData(): PaneContextData {
        return this._contextData;
    }
    get locked(): boolean {
        return this._locked;
    }

    isClosable(): boolean {
        return this.type !== "treeview";
    }

    isPinable(): boolean {
        return this.type !== "treeview" && this.type !== "form-edit" && this.type !== "action";
    }

    change(builder: PaneBuilder): Pane {
        if (builder.id && this._id) {
            throw new Error("Cannot change an existing id");
        }
        return new Pane({
            id: this._id,
            type: this._type,
            locked: this._locked,
            projectId: this._projectId,
            contextNodeId: this._contextNodeId,
            contextData: this._contextData,
            ...builder,
        });
    }
}

export type PaneType =
    | "default"
    | "search"
    | "treeview"
    | "projects"
    | "form-preview"
    | "form-edit"
    | "contacts"
    | "contact"
    | "action"
    | "report-edit"
    | "view-respondents"
    | "upload-photo-preview"
    | "classification"
    | "activities"
    | "classification-stats";



export type ActionType =
    "mass-edit" |
    "send-form" |
    "add-contact" |
    "export" |
    "merge-contacts" |
    "add-idfig-list" |
    "edit-package" |
    "share-project" |
    "create-folder" |
    "copy-form" |
    "classification";
