<div *ngIf="contact$ | async as contact" class="contact-content" [class.selected]="contactSelection$ | async">
    <diversite-photo-cover [url]="imageUrl" />
    <div class="info">
        <div class="idfig-age">
            <small>{{ contact.idfig }}</small>
            <small class="age">{{ contact.ageLiteral }}</small>
        </div>
        {{ contact.fullname }}
    </div>

    <cds-icon (click)="onDeleteClick()" class="deletable-icon" *ngIf="deletable" shape="times-circle"></cds-icon>
</div>
