<div *ngIf="isFullNodeDisplay" class="node-content node-fixed">
    <div class="node-title" (contextmenu)="onRightClickForm($event, node)">
        <cds-icon [shape]="'form'"></cds-icon>
        <input class="node-name-input" disabled [value]="'Formulaire(s)'" />
        <span class="actions">
            <clr-toggle-wrapper *ngIf="isOpen">
                <input type="checkbox" clrToggle [(ngModel)]="showHidden" name="options" />
                <label>Montrer les cachées</label>
            </clr-toggle-wrapper>
            <strong>&nbsp;&nbsp;{{ node.contextData.formIds.length }}&nbsp;&nbsp;</strong>
        </span>
    </div>
    <div class="forms" *ngIf="isOpen && forms">
        <ng-container [ngTemplateOutlet]="formsContent"></ng-container>
    </div>
</div>

<div class="node-content" *ngIf="!isFullNodeDisplay && forms">
    <div class="forms">
        <ng-container [ngTemplateOutlet]="formsContent"></ng-container>
    </div>
</div>

<ng-template #formsContent>
    <div class="form-row" #formContent [attr.data-formid]="form.id" *ngFor="let form of forms; trackBy: trackById">
        <div class="form-content" *ngIf="showHidden || !form.archived">
            <div class="left"><cds-icon class="drag" shape="drag-handle"></cds-icon>{{ form.adminName }}</div>
            <div class="right">
                <clr-icon
                    *hasAccess="{ type: 'segment', segment: 'formOperation', permissions: ['update'] }"
                    (click)="toggleArchive(form)"
                    [attr.shape]="form.archived ? 'eye' : 'eye-hide'"
                ></clr-icon>
                <clr-icon
                    *hasAccess="{ type: 'segment', segment: 'formOperation', permissions: ['read'] }"
                    (click)="onCopyFormLink(form.id)"
                    shape="link"
                ></clr-icon>
                <clr-icon
                    *hasAccess="{ type: 'segment', segment: 'formOperation', permissions: ['update'] }"
                    (click)="onEditFormClick(form.id, node)"
                    shape="note"
                ></clr-icon>
                <clr-icon
                    *hasAccess="{ type: 'segment', segment: 'formOperation', permissions: ['read'] }"
                    (click)="onViewFormClick(form.id, node)"
                    shape="list"
                ></clr-icon>
                <clr-icon
                    *hasAccess="{ type: 'segment', segment: 'formOperation', permissions: ['read'] }"
                    (click)="onViewRespondents(form.id, node)"
                    shape="users"
                ></clr-icon>
            </div>
        </div>
    </div>
</ng-template>
