import { Thumbnail } from './thumbnail';

export interface Builder {
    id?: string;
    thumbnails?: Thumbnail[];
    inputSource?: string;
    originalUrl?: string;
    uploadedAt?: Date;
    originalDate?: Date;
    rotate?: number;
    cellPicture?: boolean;
}

export class ContactImage {
    private _id: string;
    private _thumbnails: Thumbnail[];
    private _inputSource: string;
    private _originalUrl: string;
    private _faceRectangle: FaceRectangle;
    private _uploadedAt: Date;
    private _originalDate: Date;
    private _rotate: number;
    private _cellPicture: boolean;

    constructor(builder: Builder) {
        this._id = builder.id;
        this._thumbnails = builder.thumbnails || [];
        this._inputSource = builder.inputSource || null;
        this._originalUrl = builder.originalUrl || null;
        this._faceRectangle =
            this._thumbnails && this._thumbnails.length > 0 ? this._thumbnails[0].faceRectangle : null;

        this._uploadedAt = builder.uploadedAt || null;

        this._originalDate = builder.originalDate || null;
        this._rotate = builder.rotate || 0;
        this._cellPicture = builder.cellPicture === true ? true : false;
    }

    get id(): string {
        return this._id;
    }

    get url(): string {
        if (this.thumbnails && this.thumbnails.length > 0) {
            return this.thumbnails[0].thumbnailUrl;
        } else if (this._id.indexOf("thumbnailUrl") > 0) {
            return this._originalUrl;
        }
        // We do not return the original url because sometimes images are too big
        return undefined;
    }

    get width(): number {
        // if (
        //     this.thumbnails &&
        //     this.thumbnails.length > 0 &&
        //     this.thumbnails[0].metaData &&
        //     this.thumbnails[0].metaData.metadata &&
        //     this.thumbnails[0].metaData.metadata.width
        // ) {
        //     return this.thumbnails[0].metaData.metadata.width;
        // }
        if (this.thumbnails && this.thumbnails.length > 0 && this.thumbnails[0].width) {
            return this.thumbnails[0].width;
        }
        return undefined;
    }

    get inputSource(): string {
        return this._inputSource;
    }

    get height(): number {
        // if (
        //     this.thumbnails &&
        //     this.thumbnails.length > 0 &&
        //     this.thumbnails[0].metaData &&
        //     this.thumbnails[0].metaData.metadata &&
        //     this.thumbnails[0].metaData.metadata.height
        // ) {
        //     return this.thumbnails[0].metaData.metadata.height;
        // }
        if (this.thumbnails && this.thumbnails.length > 0 && this.thumbnails[0].height) {
            return this.thumbnails[0].height;
        }
        return undefined;
    }

    get originalUrl(): string {
        return this._originalUrl;
    }
    get thumbnails(): Thumbnail[] {
        return this._thumbnails;
    }

    get faceRectangle(): FaceRectangle {
        return this._faceRectangle;
    }

    get uploadedAt(): Date {
        return this._uploadedAt;
    }

    get originalDate(): Date {
        return this._originalDate;
    }

    get rotate(): number {
        return this._rotate;
    }

    get cellPicture(): boolean {
        return this._cellPicture;
    }

    changeRotation(rotation: number): ContactImage {
        return new ContactImage({
            id: this._id,
            thumbnails: this._thumbnails,
            inputSource: this._inputSource,
            originalUrl: this._originalUrl,
            uploadedAt: this._uploadedAt,
            cellPicture: this._cellPicture,
            rotate: rotation,
        });
    }
}

export interface FaceRectangle {
    top: number;
    left: number;
    width: number;
    height: number;
}
