import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, take } from 'rxjs';
import { StatisticCategory } from '../model/statistic-category';
import { StatisticCategoryGroup } from '../model/statistic-category-group';
import { StatisticSubCategory } from '../model/statistic-sub-category';
import { ContactService } from './contact.service';
import { StatisticsFilterContactStrategyService } from './statistics-filter-contact-strategy.service';



@Injectable({
    providedIn: 'root'
})
export class StatisticsCategoryService {


    constructor(private contactService: ContactService, private filterContactStrategy: StatisticsFilterContactStrategyService) { }

    allCategories(): StatisticCategory[] {

        const defaultCategories = [
            new StatisticCategory({
                id: "gender",
                name: "Genre",
                subCategories: [
                    new StatisticSubCategory({
                        id: "m",
                        name: "Homme",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("m")
                    }),
                    new StatisticSubCategory({
                        id: "f",
                        name: "Femme",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("f")
                    }),
                    new StatisticSubCategory({
                        id: "x",
                        name: "Autre",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("x")
                    })
                ]
            }),
            new StatisticCategory({
                id: "ethnicity",
                name: "Ethnicité",
                subCategories: [
                    new StatisticSubCategory({
                        id: "c",
                        name: "Caucasien",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("c")
                    }),
                    new StatisticSubCategory({
                        id: "n",
                        name: "Noir",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("n")
                    }),
                    new StatisticSubCategory({
                        id: "a",
                        name: "Asiatique",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("a")
                    }),
                    new StatisticSubCategory({
                        id: "other-race",
                        name: "Autre",
                        filterContactStrategy: this.filterContactStrategy.filterStrategyForSubCategory("other-race")
                    })
                ]
            })
        ];
        return [...defaultCategories];
    }


    mainStatisticsCategoryGroup(selectedCategoriesId: string[], contactIds: string[]): Observable<any> {

        return forkJoin(contactIds.map((cId, i) => this.contactService.contactById(cId).pipe(take(1)))).pipe(
            map(contacts => {
                const selectedCategories: StatisticCategory[] = selectedCategoriesId.map(id => this.allCategories().find(c => c.id === id))

                if (selectedCategories.length === 0) {
                    return new StatisticCategoryGroup({ contacts });
                } else {
                    return new StatisticCategoryGroup({ contacts, category: selectedCategories[0], subCategories: selectedCategories.filter((c, i) => i !== 0) });
                }
            })

        );



    }
}






