<div *ngIf="classification$ | async as classification" class="classification">
    <diversite-classification-group
        [defaultGroup]="true"
        [group]="classification.defaultGroup"
        (changeGroup)="onDefaultGroupChange(classification, $event)"
        (openClassificationStats)="onOpenStats($event)"
    />

    <diversite-classification-group
        *ngFor="let group of classification.groups; trackBy: trackById"
        [group]="group"
        (changeGroup)="onChildGroupChange(classification, $event)"
        (openClassificationStats)="onOpenStats($event)"
        (deleteGroup)="onDeleteChildGroup(classification, $event)"
    />

    <button (click)="addGroup(classification)" class="btn btn-primary btn-sm">Ajouter un groupe</button>
</div>
