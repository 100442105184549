<div
    [style.width.px]="width"
    [style.height.px]="height"
    [class.selected]="selected$ | async"
    [style.outlineColor]="selectedColor$ | async"
    class="card"
>
    <ng-container *ngIf="contactPerspective$ | async as contactPerspective">
        <div class="up">
            <div class="main-photo">
                <diversite-photo-cover [url]="defaultImage ? defaultImage.url : null" [rotate]="defaultImage?.rotate" />

                <div *ngIf="defaultImage && !isCompact" class="picture-date">
                    <span [style.zoom]="zoom">Date upload: {{ defaultImage.uploadedAt | date : "dd-MM-yyyy" }}</span
                    ><br />
                    <span [style.zoom]="zoom"
                        >Fichier:
                        <span *ngIf="defaultImage.originalDate; else nil">{{
                            defaultImage.originalDate | date : "dd-MM-yyyy"
                        }}</span
                        ><ng-template #nil>nil</ng-template></span
                    >
                </div>

                <div [style.zoom]="zoom" class="basic-info">
                    <span [hidden]="isCompact">{{ contactPerspective.contact.idfig }} </span><br /><span class="name">{{
                        contactPerspective.contact.fullname
                    }}</span
                    ><span class="age">{{ contactPerspective.contact.ageLiteral }}</span>
                </div>

                <ng-content></ng-content>
            </div>
        </div>

        <div *ngIf="infoSize > 0">
            <ng-container *ngIf="shownAttributes$ | async as shownAttributes">
                <div *ngIf="shownAttributes.length > 0" [style.height.px]="infoSize" class="attributes">
                    <diversite-search-profile-attribute
                        *ngFor="let searchAttr of shownAttributes; trackBy: trackByAttrData"
                        [attributeId]="searchAttr.attributeId"
                        [isSearched]="searchAttr.highlight ? true : false"
                        [stringValue]="
                            searchAttr.highlight || valueForIndex(contactPerspective, searchAttr.attributeId)
                        "
                        [value]="contactPerspective.contact.getAttributeValue(searchAttr.attributeId)"
                        (valueChange)="onAttributeValueChange($event, contactPerspective.contact)"
                        (openFormPane)="onOpenFormPane($event)"
                    ></diversite-search-profile-attribute>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<ng-content select="remove-contact"></ng-content>

<ng-template #placeholder>
    <div [style.width.px]="width" [style.height.px]="height" class="card loading">
        <clr-spinner clrMedium></clr-spinner>
    </div>
</ng-template>
