import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Classification } from 'src/modules/diversite/model/classification';
import { ClassificationGroup } from 'src/modules/diversite/model/classification-group';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';
import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../services/pane-reference.service';

@Component({
    selector: 'diversite-classification-pane-content-container',
    templateUrl: './classification-pane-content-container.component.html',
    styleUrl: './classification-pane-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationPaneContentContainerComponent {
    @Input() pane: Pane;


    classification$: Observable<Classification>;



    private disposeBag = new DisposeBag();

    constructor(
        private actionsService: ContextNodePaneActionsService,
        private classificationService: SearchClassificationService,
        private paneRefService: PaneReferenceService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pane?.currentValue && !this.classification$) {
            const pane = changes.pane?.currentValue;
            const classificationId = pane.contextData.classificationId;
            this.classification$ = this.classificationService.classificationForSearch(classificationId);
        }
    }


    trackById(_: number, entity: any): string {
        return entity.id;
    }

    onDefaultGroupChange(classification: Classification, defaultGroup: ClassificationGroup): void {
        this.classificationService.editClassification(classification.changeDefaultGroup(defaultGroup)).subscribe(_ => {
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    onDeleteChildGroup(classification: Classification, groupId: string): void {
        this.classificationService.editClassification(classification.removeChildGroup(groupId)).subscribe(_ => {
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    onChildGroupChange(classification: Classification, g: ClassificationGroup): void {
        this.classificationService.editClassification(classification.changeChildGroup(g)).subscribe(_ => {
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    onOpenStats(groupId: string): void {
        this.actionsService.openClassificationStatsPane(
            this.pane.contextData.classificationId,
            groupId,
            this.paneRefService.currentLayout.findIndex(paneRef => paneRef.pane.id === this.pane.id) + 1,
            this.pane.projectId
        ).subscribe()
    }

    addGroup(classification: Classification): void {
        this.classificationService.editClassification(classification.addGroup()).subscribe().disposedBy(this.disposeBag);
    }

}
