import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, interval, map, Observable, share, startWith, switchMap, tap } from 'rxjs';
import { Pane } from 'src/modules/diversite/model/pane';
import { ContactResult, SearchResultContacts } from 'src/modules/diversite/services/elasticsearch.service';
import { SearchContextService } from 'src/modules/diversite/services/search-context.service';


import { ElasticSearchMapperService } from 'src/modules/core/mappers/elasticsearch-mapper.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchResults, SearchResultsGroupBy } from 'src/modules/diversite/model/search-results';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { SearchFavoriteNode } from 'src/modules/diversite/services/search-favorite-node.service';
import { SearchProfileVisibleAttributesService } from 'src/modules/diversite/services/search-profile-visible-attributes.service';
import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../services/pane-reference.service';
import { PaneService } from '../../services/pane.service';
import { SearchParametersTab } from '../search-parameters/search-parameters.component';
import { SearchEvent } from './search-contacts-pane-content/search-contacts-pane-content.component';

@Component({
    selector: 'diversite-search-contacts-pane-content-container',
    templateUrl: './search-contacts-pane-content-container.component.html',
    styleUrl: './search-contacts-pane-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchContactsPaneContentContainerComponent {
    @Input() pane: Pane;


    loading = true;
    private canLoadMore = true;

    searchDefinition$: Observable<SearchDefinition>;
    searchState$ = new BehaviorSubject<SearchState>(new SearchState());;
    searchResults$: Observable<SearchResults>;
    favoriteSearchNodes$: Observable<SearchFavoriteNode[]>;

    searchResultsNew$: Observable<SearchResults>;

    groupedBy$ = new BehaviorSubject<SearchResultsGroupBy>("no-grouping");

    private disposeBag = new DisposeBag();

    private _accumulatorContactResults: ContactResult[] = [];

    private trigger: Trigger;

    constructor(
        private contactService: ContactService,
        private searchContextService: SearchContextService,
        private paneService: PaneService,
        private visibleAttrShownService: SearchProfileVisibleAttributesService,
        private elasticSearchMapper: ElasticSearchMapperService,
        private actionPaneService: ContextNodePaneActionsService,
        private paneRefService: PaneReferenceService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.searchDefinition$ = this.searchContextService.searchDefinition(this.pane.contextData.searchContextId, { listen: true }).pipe(distinctUntilChanged((previousSearchDefinition, currentSearchDefinition) => {
            const stopObs = this.elasticSearchMapper.mainMust(previousSearchDefinition) === this.elasticSearchMapper.mainMust(currentSearchDefinition);
            return stopObs;
        }), share());

        this.searchResults$ = combineLatest([
            this.searchDefinition$.pipe(tap(_ => this.trigger = "search")),
            this.searchState$.pipe(tap(_ => this.trigger = "search-state")),
            this.groupedBy$.pipe(tap(_ => this.trigger = "group"))
        ]).pipe(
            filter(data => data && data[0] && data[1] ? true : false),
            debounceTime(150),
            switchMap(data => {
                const searchDefinition = data[0];
                const searchState = data[1];
                if (this.canLoadMore && this.trigger === "search" || this.trigger === "search-state") {
                    return this.contactService.searchContacts(searchDefinition, new SearchState({ limit: searchState.limit, page: searchState.page, sort: searchState.sort }), { loadContact: true });
                } else {
                    return this.contactService.searchContacts(searchDefinition, new SearchState({ limit: 0, page: 1, sort: searchState.sort }));
                }
            }),
            map((results: SearchResultContacts) => {
                this.loading = false;
                if (this.trigger === "search" || this.trigger === "search-state") {
                    if (results.contactResults.length === 0) {
                        this.canLoadMore = false;
                    }

                    this._accumulatorContactResults = [...this._accumulatorContactResults, ...results.contactResults];
                    return new SearchResults({ total: results.total, groupBy: this.groupedBy$.value, contactResults: this._accumulatorContactResults });
                } else {
                    return new SearchResults({ total: results.total, groupBy: this.groupedBy$.value, contactResults: this._accumulatorContactResults })
                }
            })
        )

        this.searchResultsNew$ = combineLatest([
            this.searchDefinition$,
            this.groupedBy$,
            interval(10000).pipe(startWith(undefined))
        ]).pipe(
            switchMap(data => {
                const searchDefinition: SearchDefinition = data[0];
                return this.contactService.searchContacts(searchDefinition.change({
                    bookmarkSpec: { ...searchDefinition.bookmarkSpec, new: true }
                }), new SearchState({ limit: 9999, page: 1, sort: this.searchState$.value.sort }), { loadContact: true })
            }),
            map((results: SearchResultContacts) => {
                return new SearchResults({ total: results.total, groupBy: this.groupedBy$.value, contactResults: results.contactResults });
            }),
            startWith(new SearchResults())
        )
    }


    onSearchParametersTab(tab: SearchParametersTab): void {
        this.paneService.editPane(this.pane.change({
            contextData: { ...this.pane.contextData, tabOpen: tab }
        })).subscribe().disposedBy(this.disposeBag)
    }

    onLoadMore(searchState: SearchState): void {
        if (this.canLoadMore) {
            this.loading = true;
            this.searchState$.next(searchState)
        }
    }

    onSearch(event: SearchEvent): void {
        this.loading = true;
        this.canLoadMore = true;

        this.setVisibleAttributes(event.searchDefinition);

        this._accumulatorContactResults = [];
        this.chRef.detectChanges();
        this.searchState$.next(event.searchState);
        this.searchContextService.saveSearchDefinition(event.searchDefinition).subscribe().disposedBy(this.disposeBag);
    }


    private setVisibleAttributes(searchDefinition: SearchDefinition): void {
        if (searchDefinition) {
            if (searchDefinition.hasFormSearchOperation()) {
                this.visibleAttrShownService.setAttribute({
                    attributeId: searchDefinition.formSearchOperation().operand
                })
            } else {
                this.visibleAttrShownService.removeFormAttribute();
            }
            const notFormOperations = searchDefinition.searchOperations.filter(s => !s.operand || !s.operand.startsWith("recruitment_"));
            if (notFormOperations.length > 0) {
                notFormOperations.forEach((sop) => {
                    this.visibleAttrShownService.setAttribute({ attributeId: sop.operand });
                })
            }
        }
    }

    onMassEditResults(searchDef: SearchDefinition): void {
        this.actionPaneService
            .openActionPaneAtIndex("mass-edit", this.paneRefService.currentLayout.length + 1, {
                searchContextId: searchDef.id,
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    onContactWidthChange(width: number): void {
        this.paneService.editPane(this.pane.change({ contextData: { ...this.pane.contextData, contactCardWidth: width } })).subscribe().disposedBy(this.disposeBag);
    }

    onChangeGroupBy(groupBy: SearchResultsGroupBy): void {
        this.groupedBy$.next(groupBy);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}


type Trigger = "search" | "group" | "search-state";