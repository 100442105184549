<div class="copy-form-container">
    <div class="middle">
        <div>
            <h3>Source</h3>
            <clr-select-container>
                <label>Projet source</label>
                <select clrSelect (change)="onProjectChange()" [(ngModel)]="projectId">
                    <option value="">-</option>
                    <option [value]="project.id" *ngFor="let project of projects$ | async; trackBy: trackById">
                        {{ project.name }}
                    </option>
                </select>
            </clr-select-container>
            <clr-select-container>
                <label>Formulaire à copier</label>
                <select clrSelect (change)="onFormChange()" [(ngModel)]="formId">
                    <option value="">-</option>
                    <option [value]="form.id" *ngFor="let form of forms$ | async; trackBy: trackById">
                        {{ form.adminName }}
                    </option>
                </select>
            </clr-select-container>
        </div>
        <cds-divider />
        <div>
            <h3>Destination</h3>
            <clr-select-container>
                <label>Copier dans le project</label>
                <select clrSelect [(ngModel)]="destinationProjectId">
                    <option value="">-</option>
                    <option [value]="project.id" *ngFor="let project of projects$ | async; trackBy: trackById">
                        {{ project.name }}
                    </option>
                </select>
            </clr-select-container>
            <div class="form-name">
                <clr-input-container>
                    <label>Nouveau nom</label>
                    <input clrInput placeholder="fr" name="name" [(ngModel)]="name['fr']" />
                </clr-input-container>
                <clr-input-container>
                    <input clrInput placeholder="en" name="name" [(ngModel)]="name['en']" />
                </clr-input-container>
            </div>
        </div>
    </div>

    <div class="bottom">
        <button class="btn btn-sm" (click)="onCancel()">Annuler</button>
        <button [disabled]="disabled()" (click)="copyForm()" type="button" class="btn btn-primary btn-sm">
            Copier le formulaire
        </button>
    </div>
</div>
