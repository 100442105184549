import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, distinctUntilChanged, map, Observable, of, switchMap, tap } from 'rxjs';
import { Classification } from '../model/classification';
import { ClassificationGroup } from '../model/classification-group';


@Injectable({
    providedIn: 'root'
})
export class SearchClassificationService {

    private classificationsForSearch$ = new BehaviorSubject<Map<string, Classification>>(new Map<string, Classification>());

    constructor(private db: AngularFirestore) { }

    classificationForSearch(searchId: string): Observable<Classification> {
        if (!this.classificationsForSearch$.value.has(searchId)) {
            return this.db.collection("classifications").doc(searchId).get().pipe(
                tap(doc => {
                    if (doc.exists) {
                        const data: any = doc.data();
                        this.changeClassification(this.classificationDtoToClassification({ ...data, id: doc.id }), false);
                    } else {

                        this.changeClassification(this.defaultClassification(searchId), false)
                    }
                }), switchMap(_ => {
                    return this.classificationsForSearch$.pipe(
                        map(map => map.get(searchId))
                    )
                })
            )
        }

        return this.classificationsForSearch$.pipe(
            map(map => map.get(searchId)),
        )
    }

    classificationForContact(searchId: string, contactId: string): Observable<ClassificationGroup> {
        return this.classificationForSearch(searchId).pipe(
            map(classification => {
                return classification.groupForContact(contactId);
            }),
            distinctUntilChanged(),
        );
    }

    editClassification(classification: Classification): Observable<Classification> {
        this.changeClassification(classification)
        return of(classification);
    }

    private defaultClassification(searchId: string): Classification {
        return new Classification({
            id: searchId
        })
    }

    toggleSelection(searchId: string, contactId: string, classificationGroupId?: string): void {
        const classification = this.classificationsForSearch$.value.get(searchId);
        const toggled = classification.toggleContact(contactId, classificationGroupId);
        this.changeClassification(toggled);
    }

    private changeClassification(classification: Classification, save = true): void {
        const map = this.classificationsForSearch$.value;
        map.set(classification.id, classification);
        if (save) {
            this.saveClassification(classification);
        }
        this.classificationsForSearch$.next(map);

    }

    private saveClassification(classification: Classification): void {
        this.db.collection("classifications").doc(classification.id).set(this.classificationToClassificationDTO(classification));
    }

    private classificationToClassificationDTO(classification: Classification): ClassificationDTO {
        return {
            id: classification.id,
            defaultGroup: this.classificationGroupToClassificationGroupDTO(classification.defaultGroup),
            groups: classification.groups.map(g => this.classificationGroupToClassificationGroupDTO(g))
        }
    }

    private classificationGroupToClassificationGroupDTO(group: ClassificationGroup): ClassificationGroupDTO {
        return {
            id: group.id,
            name: group.name,
            contactIds: group.contactIds,
            groups: group.groups.map(g => this.classificationGroupToClassificationGroupDTO(g)),
            color: group.color
        }
    }

    private classificationDtoToClassification(classificationDto: ClassificationDTO): Classification {
        return new Classification({
            ...classificationDto,
            defaultGroup: this.classificationGroupDtoToClassificationGroup(classificationDto.defaultGroup),
            groups: classificationDto.groups.map(gdto => this.classificationGroupDtoToClassificationGroup(gdto))
        });
    }

    private classificationGroupDtoToClassificationGroup(groupDto: ClassificationGroupDTO): ClassificationGroup {
        return new ClassificationGroup({
            ...groupDto,
            groups: groupDto.groups.map(gdto => this.classificationGroupDtoToClassificationGroup(gdto))
        });
    }

}



export interface ClassificationDTO {
    id: string;
    defaultGroup: ClassificationGroupDTO;
    groups: ClassificationGroupDTO[];
}

export interface ClassificationGroupDTO {
    id: string;
    name: string;
    contactIds: string[];
    groups: ClassificationGroupDTO[];
    color: string;
}