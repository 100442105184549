import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, switchMap } from 'rxjs';
import { ClassificationGroup } from 'src/modules/diversite/model/classification-group';
import { Pane } from 'src/modules/diversite/model/pane';
import { StatisticCategory } from 'src/modules/diversite/model/statistic-category';
import { StatisticCategoryGroup } from 'src/modules/diversite/model/statistic-category-group';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';
import { StatisticsCategoryService } from 'src/modules/diversite/services/statistics-category.service';


declare var $: any;

@Component({
    selector: 'diversite-classification-stats-pane-content-container',
    templateUrl: './classification-stats-pane-content-container.component.html',
    styleUrl: './classification-stats-pane-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationStatsPaneContentContainerComponent {
    @Input() pane: Pane;




    selectedCategory: string = "";

    selectedCategories$ = new BehaviorSubject<string[]>([]);
    availableStatsCategories: StatisticCategory[] = [];

    classificationGroup$: Observable<ClassificationGroup>;
    selectedStatsCategories$: Observable<StatisticCategoryGroup[]>;

    cardSize: number = 80;

    mainStatisticGroup$: Observable<StatisticCategoryGroup>;

    constructor(
        private classificationService: SearchClassificationService,
        private statsCategoryService: StatisticsCategoryService,
        private host: ElementRef,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.classificationGroup$ = this.classificationService.classificationForSearch(this.pane.contextData.classificationId).pipe(
            map(c => {
                return c.group(this.pane.contextData.classificationGroupId);
            })
        );

        this.availableStatsCategories = this.statsCategoryService.allCategories();

        this.mainStatisticGroup$ = combineLatest([
            this.selectedCategories$,
            this.classificationGroup$
        ]).pipe(
            debounceTime(100),
            switchMap(data => {
                return this.statsCategoryService.mainStatisticsCategoryGroup(data[0], data[1].contactIds)
            })
        )

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            $(".ordered-category", this.host.nativeElement).sortable({
                update: (event, ui) => {
                    const orderedCategory = $("[data-categoryid]", event.target).toArray().map(e => e.getAttribute("data-categoryid"));
                    this.selectedCategories$.next(orderedCategory);

                    setTimeout(() => {
                        this.chRef.detectChanges();
                    }, 150);
                }
            })
        }, 1500);
    }

    onRemoveCategoryGroup(categoryId: string): void {
        this.selectedCategories$.next(this.selectedCategories$.value.filter(cId => cId !== categoryId));
    }

    category(categoryId: string): StatisticCategory {
        return this.availableStatsCategories.find(c => c.id === categoryId);
    }

    addCategory(): void {
        if (!this.selectedCategories$.value.includes(this.selectedCategory)) {
            this.selectedCategories$.next([...this.selectedCategories$.value, this.selectedCategory]);
            this.selectedCategory = "";
        }
    }

    trackByContactId(_: number, contactId: string): string {
        return contactId;
    }
    trackById(_: number, entity: { id: string }): string {
        return entity.id;
    }

}

