<div class="group" [class.default-group]="defaultGroup" [style.borderColor]="group.color">
    <div class="header-group">
        <ngx-colors ngx-colors-trigger [ngModel]="group.color" (ngModelChange)="onColorChange($event)"> </ngx-colors>
        <input type="text" [value]="group.name" (change)="onNameChange($event.target.value)" />
        <div class="actions" [hidden]="group.contactIds.length === 0">
            <a class="action-link" (click)="copySelectionInfo()"
                ><cds-icon shape="view-list"></cds-icon> Copier (excel)</a
            >
            <a class="action-link" (click)="openDownloadPane()"><cds-icon shape="download"></cds-icon> Télécharger</a>
            <a class="action-link" (click)="createReport()"><cds-icon shape="clipboard"></cds-icon> Rapport</a>
            <a class="action-link" (click)="openMassEditPane()"><cds-icon shape="users"></cds-icon> Modifier</a>
            <a class="action-link" (click)="openMergeMembersPane()"><cds-icon shape="connect"></cds-icon> Fusionner</a>
            <a class="action-link" (click)="openStatsPane()"><cds-icon shape="bar-chart"></cds-icon> Stats</a>
        </div>
        <cds-icon (click)="onDeleteGroup()" shape="times"></cds-icon>
    </div>

    <div [id]="'contacts_' + group.id" class="contacts classification-group-sortable">
        <diversite-contact-simple-profile
            *ngFor="let contactId of group.contactIds; trackBy: trackByContactId"
            [attr.data-contactid]="contactId"
            [attr.data-groupid]="group.id"
            [contactId]="contactId"
            [deletable]="true"
            (delete)="onDelete($event)"
            (viewProfile)="onViewProfile($event)"
        ></diversite-contact-simple-profile>
    </div>

    <diversite-classification-group
        *ngFor="let g of group.groups; trackBy: trackById"
        [group]="g"
        (changeGroup)="onChangeChildGroup($event)"
        (openClassificationStats)="onOpenClassificationStats($event)"
        (deleteGroup)="onDeleteChildGroup($event)"
    />

    <button (click)="addChildGroup()" class="btn btn-primary btn-sm">Ajouter un groupe</button>
</div>

<table class="hidden table-for-copy">
    <tr *ngFor="let contactPerspective of toCopyCPs; trackBy: trackById">
        <td>{{ contactPerspective.contact.idfig }}</td>
        <td>{{ contactPerspective.contact.fullname }}</td>
        <td>{{ contactPerspective.contact.email }}</td>
        <td>{{ contactPerspective.contact.mainPhoneLiteral }}</td>
        <td>{{ contactPerspective.contact.age }}</td>
        <td>{{ contactPerspective.contact.sex }}</td>
    </tr>
</table>
