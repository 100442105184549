import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Classification } from 'src/modules/diversite/model/classification';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';

import { PaneReferenceService } from '../../../services/pane-reference.service';

@Component({
    selector: 'diversite-classification-widget',
    templateUrl: './classification-widget.component.html',
    styleUrl: './classification-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationWidgetComponent {
    @Input() classification: Classification;
    @Input() pane: Pane;

    @Output() changePosition = new EventEmitter<SelectionPosition>();


    private disposeBag = new DisposeBag();

    faceSize = 100;

    constructor(
        private paneRefService: PaneReferenceService,
        private actionsPaneService: ContextNodePaneActionsService,
        private classificationService: SearchClassificationService,
        private chRef: ChangeDetectorRef
    ) { }

    trackById(_: number, contactId: string): string {
        return contactId;
    }


    openClassificationPane(): void {
        this.actionsPaneService.openClassificationPane(
            this.classification.id,
            this.paneRefService.currentLayout.findIndex(paneRef => paneRef.pane.id === this.pane.id) + 1,
            this.pane.projectId
        ).subscribe().disposedBy(this.disposeBag);
    }

    onDelete(contactId: string): void {
        this.classificationService.editClassification(this.classification.changeDefaultGroup(this.classification.defaultGroup.toggleContact(contactId))).subscribe(_ => {
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    onChangePosition(pos: SelectionPosition): void {
        this.changePosition.emit(pos)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export type SelectionPosition = "top" | "bottom" | "left" | "right"