import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Contact } from 'src/modules/diversite/model/contact';
import { StatisticCategoryGroup } from 'src/modules/diversite/model/statistic-category-group';

@Component({
    selector: 'diversite-statistic-group',
    templateUrl: './statistic-group.component.html',
    styleUrl: './statistic-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticGroupComponent {
    @Input() statisticGroup: StatisticCategoryGroup;
    @Input() cardSize: number = 80;

    private disposeBag = new DisposeBag();


    constructor(private actionsService: ContextNodePaneActionsService) { }

    trackById(_: number, c: Contact): string {
        return c.id;
    }

    onOpenProfile(c: Contact): void {
        this.actionsService.openContactProfilePane(c.id).subscribe().disposedBy(this.disposeBag)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
