import { Contact } from "./contact";
import { StatisticCategory } from "./statistic-category";
import { StatisticSubCategory } from "./statistic-sub-category";
import { StatisticSubCategoryGroup } from "./statistic-sub-category-group";

export class StatisticCategoryGroup {
    private _category: StatisticCategory;
    private _subCategoryGroups: StatisticSubCategoryGroup[];


    constructor(builder?: { category?: StatisticCategory, contacts: Contact[], subCategories?: StatisticCategory[] }) {
        this._category = builder?.category || StatisticCategory.DEFAULT;
        this._subCategoryGroups = this.buildSubCategoryGroups(builder?.contacts || [], builder.subCategories);
    }

    get category(): StatisticCategory {
        return this._category;
    }

    get subCategoryGroups(): StatisticSubCategoryGroup[] {
        return this._subCategoryGroups.filter(g => g.contacts.length > 0);
    }

    private buildSubCategoryGroups(contacts: Contact[], subCategories: StatisticCategory[]): StatisticSubCategoryGroup[] {

        return this.category.subCategories.map((subCategory: StatisticSubCategory) => {
            const categoryContacts = subCategory.filterElligibleContacts(contacts);
            return new StatisticSubCategoryGroup({
                id: subCategory.id,
                name: subCategory.name,
                contacts: categoryContacts,
                percentage: Math.floor((categoryContacts.length / contacts.length) * 100),
                statisticCategoryGroup: subCategories?.length > 0 ? new StatisticCategoryGroup({ contacts: categoryContacts, category: subCategories[0], subCategories: subCategories.filter((c, i) => i !== 0) }) : null,
            })
        })
    }
}
