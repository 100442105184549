<div class="search-parameters">
    <div class="action top">
        <button (click)="onSearch()" class="btn btn-primary btn-sm">Rechercher</button>
        <button
            *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }"
            (click)="resetSearch()"
            class="btn btn-sm"
        >
            Réinitialiser
        </button>
    </div>
    <div class="params">
        <as-split>
            <ng-container *ngIf="searchDefinition?.searchGroup">
                <ng-container
                    *ngFor="
                        let searchGroup of searchDefinition.searchGroup.searchGroups;
                        let i = index;
                        trackBy: trackById
                    "
                >
                    <as-split-area>
                        <diversite-search-group
                            [searchDefinition]="searchDefinition"
                            [projectId]="project"
                            [favoriteNodes]="favoriteNodes"
                            [contextNodeId]="contextNodeId"
                            [index]="i"
                            [metaForGroup]="
                                searchMeta && searchMeta.searchGroupTotals
                                    ? searchMeta.searchGroupTotals[searchGroup.id]
                                    : 0
                            "
                            [searchGroup]="searchGroup"
                            (removeGroup)="removeSearchGroup(i)"
                            (addOrSearchGroup)="addOrSearchGroup($event)"
                            (editGroup)="onEditGroup($event, i)"
                            (addToFavorite)="addToFavorite($event)"
                            (viewGroupResults)="onViewGroupResults($event)"
                            (projectChange)="onProjectChange($event)"
                        />
                    </as-split-area>
                </ng-container>
            </ng-container>
        </as-split>
    </div>
    <div class="action bottom">
        <button (click)="onSearch()" class="btn btn-primary btn-sm">Rechercher</button>
        <button
            *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }"
            (click)="resetSearch()"
            class="btn btn-sm"
        >
            Réinitialiser
        </button>
    </div>
</div>
