import { StatisticSubCategory } from "./statistic-sub-category";

export class StatisticCategory {
    private _id: string;
    private _name: string; // Genre, Ethnicité
    private _subCategories: StatisticSubCategory[];

    static get DEFAULT(): StatisticCategory {
        return new StatisticCategory({
            id: "main",
            name: "DEFAULT",
            subCategories: [StatisticSubCategory.DEFAULT]
        })
    }

    constructor(builder: { id: string, name: string, subCategories: StatisticSubCategory[] }) {
        this._id = builder.id;
        this._name = builder.name;
        this._subCategories = builder.subCategories || [];
    }

    get id(): string { return this._id; }
    get name(): string { return this._name; }
    get subCategories(): StatisticSubCategory[] { return this._subCategories; }
}
