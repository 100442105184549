import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, delay, filter, finalize, skip, take, tap } from 'rxjs/operators';
import { copyToClipboard, slugify } from 'src/app/core/functions';
import { PublicContext } from 'src/modules/core/model/context';
import { FileUploadImageService } from 'src/modules/core/services/file-upload-image.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ModuleMapperService } from 'src/modules/diversite/mappers/module-mapper.service';
import { Builder, ElementStructure, Form, Variable } from 'src/modules/diversite/model/form/form';
import { FormAvailability } from 'src/modules/diversite/model/form/form-element/form-availability';
import { FormCheckbox } from 'src/modules/diversite/model/form/form-element/form-checkbox';
import { FormDatetime } from 'src/modules/diversite/model/form/form-element/form-datetime';
import { FormDropdown } from 'src/modules/diversite/model/form/form-element/form-dropdown';
import { FormElement, FormElementInput, FormElementType } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormFileupload } from 'src/modules/diversite/model/form/form-element/form-fileupload';
import { FormHeight } from 'src/modules/diversite/model/form/form-element/form-height';
import { FormImageupload } from 'src/modules/diversite/model/form/form-element/form-imageupload';
import { FormNumber } from 'src/modules/diversite/model/form/form-element/form-number';
import { FormOption } from 'src/modules/diversite/model/form/form-element/form-option';
import { FormPhone } from 'src/modules/diversite/model/form/form-element/form-phone';
import { FormRadio } from 'src/modules/diversite/model/form/form-element/form-radio';
import { FormTable } from 'src/modules/diversite/model/form/form-element/form-table';
import { FormText } from 'src/modules/diversite/model/form/form-element/form-text';
import { FormTextarea } from 'src/modules/diversite/model/form/form-element/form-textarea';
import { FormTextbox } from 'src/modules/diversite/model/form/form-element/form-textbox';
import { FormUnion } from 'src/modules/diversite/model/form/form-element/form-union';
import { FormVideoupload } from 'src/modules/diversite/model/form/form-element/form-videoupload';
import { FormWeight } from 'src/modules/diversite/model/form/form-element/form-weight';
import { FormModule } from 'src/modules/diversite/model/form/form-module';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { CustomCopyPasteService } from 'src/modules/diversite/services/custom-copy-paste.service';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';
import { FormBackupService } from 'src/modules/diversite/services/form-backup.service';
import { FormLock, FormLockService } from 'src/modules/diversite/services/form-lock.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ModulesAttributesService } from 'src/modules/diversite/services/modules-attributes.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { ContextNodePaneActionsService } from '../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../dashboard/services/pane-reference.service';

declare let $: any;


@Component({
    selector: "diversite-form-edit",
    templateUrl: "./form-edit.component.html",
    styleUrls: ["./form-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormEditComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() initForm: Form;
    readonly form$ = new BehaviorSubject<Form>(undefined);
    @Input() paneId: string;
    @Input() projectId: string;
    @Input() publicContexts: PublicContext[] = [];
    @Input() templateModules: FormModule[];
    @Input() questionLibraryModules: FormModule[];
    @Input() contextNodeId: string;

    @Output() saveForm = new EventEmitter<SaveFormEvent>();
    @Output() addQuestionLibModule = new EventEmitter<FormModule>();
    @Output() editQuestionLibModule = new EventEmitter<FormModule>();
    @Output() editQuestionLibModules = new EventEmitter<FormModule[]>();
    @Output() deleteQuestionLibModule = new EventEmitter<string>();
    @Output() disablePublicContext = new EventEmitter<PublicContext>();
    @Output() enablePublicContext = new EventEmitter<PublicContext>();
    @Output() removePublicContext = new EventEmitter<PublicContext>();

    @Output() addFormLink = new EventEmitter<AddFormLinkEvent>();
    @ViewChild("publishForm", { read: NgForm }) public publishForm: NgForm;

    @HostListener("window:beforeunload", ["$event"])
    async beforeunloadHandler(event) {
        await this.triggerSyncClose();
    }

    openedModules = {};

    formActive = true;
    filtersActive = false;
    optionsActive = false;

    showDeleted = false;

    private draggedNewFormElement: FormElement;
    private draggedLibFormElement: FormElement;
    private draggedExistingFormElement: FormElement;
    private draggedNewFormElementFromTemplate: FormElement;
    private draggedFormModule: FormModule;

    private _newFormElementStrategy = new Map<FormElementType, () => FormElement>();
    publishPrompt = false;

    endText: TranslatableLabel = {};
    saveStatus: FormStatus;
    projectName = "";
    recruitmentProjetId = "";
    urlSlug = "";
    panelLibOpen = false;
    panelTemplateOpen = false;
    panelInputOpen = false;
    panelVariablesOpen = false;
    dragDropDefinition$ = new Subject();
    delayedEndTextChanged$ = new BehaviorSubject<void>(undefined);
    openBackup = false;
    translateModalOpen = false;
    variableName = "";
    openAddVariablePrompt = false;
    logoUploadPercentage = 0;
    logoUploading = false;
    selectedFormElementId = null;

    private _disposeBag = new DisposeBag();

    private editingLanguage$ = new BehaviorSubject<string>(undefined);
    private sortLib$ = new BehaviorSubject<{ event: any; ui: any }>(undefined);
    private sortLibs$ = new BehaviorSubject<{ event: any; ui: any }>(undefined);

    private formLock: FormLock;
    formLockChecked = false;

    constructor(
        private customCopyPaste: CustomCopyPasteService,
        private notificationService: NotificationService,
        private moduleMapper: ModuleMapperService,
        private formBackupService: FormBackupService,
        private formService: FormService,
        private translateService: TranslateService,
        private modulesAttributesService: ModulesAttributesService,
        private formLockService: FormLockService,
        private fileUploadService: FileUploadImageService,
        private contextNodeActionService: ContextNodePaneActionsService,
        private paneRefService: PaneReferenceService,
        private db: AngularFirestore,
        private chRef: ChangeDetectorRef
    ) {
        this._newFormElementStrategy.set("textbox", () =>
            FormTextbox.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("textarea", () =>
            FormTextarea.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("number", () =>
            FormNumber.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("datetime", () =>
            FormDatetime.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("radio", () =>
            FormRadio.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("checkbox", () =>
            FormCheckbox.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("dropdown", () =>
            FormDropdown.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("union", () =>
            FormUnion.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("phone", () =>
            FormPhone.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("height", () =>
            FormHeight.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("weight", () =>
            FormWeight.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("imageupload", () =>
            FormImageupload.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("videoupload", () =>
            FormVideoupload.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("fileupload", () =>
            FormFileupload.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("availability", () =>
            FormAvailability.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("table", () =>
            FormTable.defaultElement().change({ name: this.db.createId() })
        );
        this._newFormElementStrategy.set("text", () => FormText.defaultElement().change({ name: this.db.createId() }));
    }

    async triggerSyncClose() {
        if (this.isLocked) {
            return await this.formLockService.unlockForm(this.initForm.id);
        }
    }

    ngOnInit(): void {
        this.delayedEndTextChanged$.pipe(debounceTime(500), skip(1)).subscribe(() => {
            this.changeAndAutoSaveData({
                endText: { ...this.endText },
            });
        });

        this.formLockService.lockForm(this.initForm.id).subscribe((response) => {
            if (response.success) {
                this.formLock = response.formLock;
            }
            this.formLockChecked = true;
            this.chRef.detectChanges();
        });

        this.form$
            .pipe(
                debounceTime(250),
                tap((form) => {
                    if (form.languages.length === 0) {
                        this.setCurrentEditingLanguage(this.translateService.lang);
                        this.changeAndAutoSaveForm(form.change({ languages: [this.translateService.lang] }));
                    } else if (!this.editingLanguage) {
                        this.setCurrentEditingLanguage(form.languages[0]);
                    }
                }),
                skip(1),
                tap((_) => {
                    this.saveStatus = {
                        ...this.saveStatus,
                        edited: true,
                    };
                })
            )
            .subscribe((_) => {
                this.save();
                this.chRef.detectChanges();
            })
            .disposedBy(this._disposeBag);

        this.customCopyPaste.customPaste$
            .subscribe((formElementDto) => {
                const element = this.moduleMapper.formElementDtoToFormElement({
                    ...formElementDto,
                    id: this.db.createId(),
                });
                this.pasteFormElement(element);
            })
            .disposedBy(this._disposeBag);

        this.customCopyPaste.customCopy$
            .subscribe((_) => {
                this.notificationService.show("Votre élément à été copié dans le presse-papier.", "info");
            })
            .disposedBy(this._disposeBag);

        this.dragDropDefinition$.pipe(debounceTime(500)).subscribe(() => {
            this.dragDropDefinition();
        });

        this.formBackupService.backupForm(this.initForm).pipe(take(1)).subscribe().disposedBy(this._disposeBag);

        this.sortLib$
            .pipe(skip(1), debounceTime(1500))
            .subscribe((data) => {
                this.sortLib(data.event, data.ui);
            })
            .disposedBy(this._disposeBag);
        this.sortLibs$
            .pipe(skip(1), debounceTime(1500))
            .subscribe((data) => {
                this.sortLibs(data.event, data.ui);
            })
            .disposedBy(this._disposeBag);
    }

    ngAfterViewInit(): void {
        this.setupDragDrop();
    }

    trackBySlug(_: any, context: PublicContext): string {
        return context.slug;
    }

    url(context: PublicContext): string {
        return context ? `${window.location.origin}/#/c/${context.slug}` : "";
    }

    disableLink(context: PublicContext): void {
        this.disablePublicContext.emit(context);
    }

    enableLink(context: PublicContext): void {
        this.enablePublicContext.emit(context);
    }

    deleteLink(context: PublicContext): void {
        this.removePublicContext.emit(context);
    }

    copyUrl(event: MouseEvent): void {
        if (copyToClipboard(event.target)) {
            this.notificationService.show("Le lien à été copié dans le clipboard.", "info");
        }
    }

    selectFormElement(formElement: FormElement): void {
        setTimeout(() => {
            if (this.selectedFormElementId !== formElement.id) {
                this.selectedFormElementId = formElement.id;
                this.chRef.detectChanges();
            }
        });
    }

    deselectFormElement(): void {
        if (this.selectedFormElementId) {
            this.selectedFormElementId = null;
            this.chRef.detectChanges();
        }
    }

    labelFormLang(formElement: FormElement, lang: string): string {
        if (formElement.isInput()) {
            return (formElement as FormElementInput).label[lang] || "";
        }
        if (formElement instanceof FormText) {
            return (formElement as FormText).text[lang] ? (formElement as FormText).text[lang] : "";
        }
        return "";
    }

    get isLocked(): boolean {
        return this.formLock ? true : false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initForm && changes.initForm.currentValue) {
            if (!this.form$.value) {
                this.form$.next(this.initForm);
                this.saveStatus = {
                    edited: false,
                    lastSave: undefined,
                };
                this.endText = this.initForm.endText;
            }

            if (
                changes.initForm.previousValue &&
                changes.initForm.previousValue.isPublished === false &&
                changes.initForm.currentValue.isPublished === true
            ) {
                this.publishPrompt = false;
            }
        }

        this.setupDragDrop();
    }

    openTranslateModal(): void {
        if (this.form$.value.languages.length > 1) {
            this.translateModalOpen = true;
        }
    }

    openRespondentsPane(): void {
        if (this.publicContexts && this.publicContexts.length > 0) {
            // this.contextNodeActionService.openFormRespondentsPane(this.form$.value.id, this.contextNodeId);
            this.contextNodeActionService.openSearchPane(SearchDefinition.DEFAULT_SEARCH_DEFINITION_FORM(this.db.createId(), this.form$.value.id), this.projectId, this.contextNodeId).subscribe().disposedBy(this._disposeBag);
        }
    }

    hasLanguage(lang: string): boolean {
        return this.form$.value.hasLanguage(lang);
    }

    toggleLanguage(lang: string): void {
        const langs = this.form$.value.languages;
        let newLangs = [];
        if (langs.includes(lang)) {
            newLangs = langs.filter((l) => lang !== l);
            if (lang === this.editingLanguage) {
                this.setCurrentEditingLanguage(newLangs[0]);
            }
        } else {
            newLangs = [...langs, lang];
        }
        this.changeAndAutoSaveForm(this.form$.value.change({ languages: newLangs }));
    }

    trackByFnElement(index: number, formElement: FormElement): string {
        return `${index}-${formElement.id}`;
    }
    trackByFnModules(index: number, formTemplate: FormModule): string {
        return `${index}-${formTemplate.id}`;
    }
    trackById(_: any, fe: FormElement): string {
        return fe.id;
    }

    publishValid(): boolean {
        return this.publishForm && !this.publishForm.controls.urlSlug.invalid;
    }

    private changeAndAutoSaveData(builder: Builder): void {
        this.form$.next(this.form$.value.change(builder));
    }
    changeAndAutoSaveForm(form: Form): void {
        this.form$.next(form);
    }

    addVariablePrompt(): void {
        this.openAddVariablePrompt = true;
    }

    cancelAddVariable(): void {
        this.openAddVariablePrompt = false;
    }

    addVariable(): void {
        this.changeAndAutoSaveForm(
            this.form$.value.addVariable(new Variable({ id: this.db.createId(), name: this.variableName }))
        );
        this.variableName = "";
        this.openAddVariablePrompt = false;
    }

    removeVariable(vid: string): void {
        this.changeAndAutoSaveForm(this.form$.value.removeVariable(vid));
    }

    onFormElementChange(formElement: FormElement, module: FormModule): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => (m.id === module.id ? m.changeFormElement(formElement) : m)),
        });
    }

    onTextTranslateChanged(html: string, formElement: FormText, lang: string): void {
        if (html) {
            const text = { ...formElement.text };
            text[lang] = html;
            const form = this.form$.value.changeFormElement(formElement.change({ text }));
            this.changeAndAutoSaveForm(form);
        }
    }

    onInputTranslateChange(value: string, formElement: FormElementInput, lang: string): void {
        const label = { ...formElement.label };
        label[lang] = value;
        this.changeAndAutoSaveForm(this.form$.value.changeFormElement(formElement.change({ label })));
    }

    onInputOptionTranslateChange(value: string, formElement: FormElement, option: FormOption, lang: string): void {
        this.changeAndAutoSaveForm(
            this.form$.value.changeFormElement(
                formElement.change({
                    options: (formElement as any).options.map((opt) => {
                        if (opt.id === option.id) {
                            opt.label[lang] = value;
                            return { ...opt };
                        }
                        return { ...opt };
                    }),
                })
            )
        );
    }

    previewMode(): void {
        this.contextNodeActionService.openFormPane(this.form$.value.id, this.contextNodeId);
    }

    get urlFirstPart(): string {
        return `${window.location.origin}/#/c/`;
    }

    get editingLanguage(): string {
        return this.editingLanguage$.value;
    }

    onLogoInputChange(event: any, retry = false): void {
        if (event.target.files.length > 0) {
            this.logoUploadPercentage = 0;
            this.logoUploading = true;
            // this.files = event.target.files;
            this.fileUploadService
                .uploadFiles(Array.from(event.target.files))
                .pipe(
                    tap((uploadVideoResponse: UploadFilesResponse) => {
                        this.logoUploadPercentage = uploadVideoResponse.percentage;
                        this.chRef.detectChanges();
                    }),
                    filter((uploadVideoResponse: UploadFilesResponse) => {
                        return uploadVideoResponse.percentage === 100;
                    }),
                    take(1),
                    delay(500),
                    catchError((err) => {
                        if (!retry) {
                            this.onLogoInputChange(event, true);
                            return null;
                        }
                        return throwError(err);
                    }),
                    finalize(() => { })
                )
                .subscribe((uploadVideoResponse) => {
                    if (uploadVideoResponse.urls && uploadVideoResponse.urls.length > 0) {
                        this.changeFormLogo(uploadVideoResponse.urls[0]);
                    }
                    this.logoUploading = false;
                    this.chRef.detectChanges();
                })
                .disposedBy(this._disposeBag);
        }
    }

    onModuleNameChange(event: Event, module: FormModule): void {
        const input = event.target as HTMLInputElement;
        const name: TranslatableLabel = { ...module.name };
        name[this.editingLanguage] = input.value;

        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => (m.id === module.id ? m.change({ name }) : m)),
        });
    }

    deleteModule(moduleId: string): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => (m.id === moduleId ? m.change({ deleted: true }) : m)),
        });
    }

    reactivateModule(moduleId: string): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => (m.id === moduleId ? m.change({ deleted: false }) : m)),
        });
    }

    setCurrentEditingLanguage(lang: string, saveForm = false): void {
        if (saveForm) {
            this.changeAndAutoSaveForm(this.form$.value.setDefaultValueForNewLang(this.translateService.lang, lang));
        }

        this.editingLanguage$.next(lang);
        this.chRef.markForCheck();
    }

    label(fe: FormElement): string {
        if (fe.isInput()) {
            return (fe as FormElementInput).label[this.editingLanguage] || "";
        }
        return "";
    }

    deleteFormElement(moduleId: string, formElementId: string): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => {
                if (m.id === moduleId) {
                    return m.deleteFormElement(formElementId);
                }
                return m;
            }),
        });
    }

    reactivateFormElement(moduleId: string, formElementId: string): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((m) => {
                if (m.id === moduleId) {
                    return m.reactivateFormElement(formElementId);
                }
                return m;
            }),
        });
    }

    private save(): void {
        const errors = this.validateForm(this.form$.value);
        if (errors && Object.keys(errors).length > 0) {
            this.saveStatus = { edited: true, lastSave: this.saveStatus.lastSave, errors };
            this.notificationService.show(
                "Le formulaire ne peut pas être sauvegardé. Veuillez corriger les erreurs dans le formulaire",
                "warning"
            );
            this.chRef.detectChanges();
        } else {
            if (this.formLock) {
                this.saveStatus = { edited: false, lastSave: new Date() };
            }
            this.emitSaveForm(this.form$.value);
        }
    }

    onTranslateModalOpenChange(isOpen: boolean): void {
        if (!isOpen) {
            const form: Form = this.form$.value;
            this.form$.next(undefined);
            this.chRef.detectChanges();
            this.translateModalOpen = false;
            this.form$.next(form);
            this.chRef.detectChanges();
        }
    }

    private validateForm(form: Form): {
        [k: string]: FormError;
    } {
        const errors = new Map<string, FormError>();
        const inputNames = form.activeFormElements().map((fe) => fe.name);
        const alreadySeen = [];

        inputNames.forEach((str) => {
            if (alreadySeen[str] && !errors.has(str)) {
                errors.set(str, { inputName: str, errorType: "duplicate" });
            } else {
                alreadySeen[str] = true;
            }
        });
        return Object.fromEntries(errors.entries());
    }

    saveACopy(): void {
        this.contextNodeActionService.openActionPaneAtIndex(
            "copy-form",
            this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.paneId) + 1,
            {
                projectId: this.projectId,
                formId: this.form$.value.id
            }
        ).subscribe().disposedBy(this._disposeBag);
    }

    saveAsTemplate(): void {
        this.formService.saveAsTemplate(this.form$.value).subscribe();
        // this.emitSaveForm(this.formService.makeATemplate(this.form$.value));
    }

    private emitSaveForm(form: Form): void {
        if (this.formLock) {
            this.saveForm.emit({ form, formLockHash: this.formLock.hash });
        }
    }

    isOpen(moduleId: string): boolean {
        return this.openedModules[moduleId] === true ? true : false;
    }

    changeOpenState(formTemplateId: string): void {
        this.openedModules[formTemplateId] = !this.openedModules[formTemplateId];
    }

    changeFormName(input: HTMLInputElement): void {
        const name = { ...this.form$.value.name };
        name[this.editingLanguage] = input.value;
        this.changeAndAutoSaveData({
            name,
        });
    }

    changeFormLogo(url: string): void {
        this.changeAndAutoSaveData({
            logo: url,
        });
    }

    onEndTextChanged(html: string, forceLang?: string): void {
        if (html) {
            this.endText[forceLang ? forceLang : this.editingLanguage] = html;
            this.delayedEndTextChanged$.next();
        }
    }

    private setupDragDrop(): void {
        this.dragDropDefinition$.next(undefined);
    }

    private dragDropDefinition(): void {
        $("#template-modules").sortable({
            connectWith: "#modules",
            handle: ".drag-module",
            helper: "clone",
            tolerance: "pointer",
            placeholder: "element-placeholder",
            opacity: ".7",
            start: (event, ui) => {
                const moduleId = $("[data-moduleid]", ui.item).attr("data-moduleid");
                if (moduleId) {
                    this.draggedFormModule = this.templateModules.find((m) => m.id === moduleId);
                }
            },
            stop: () => {
                this.setupDragDrop();
                this.hackToResetView();
            },
        });

        $("#library-modules").sortable({
            connectWith: "#modules",
            handle: ".drag-module",
            helper: "clone",
            placeholder: "element-placeholder",
            tolerance: "pointer",
            opacity: ".7",
            receive: (event, ui) => {
                const moduleId = ui.item.attr("id");
                const module = this.form$.value.modules.find((m) => m.id === moduleId);
                if (module) {
                    this.addQuestionLibMod(module);
                }
                ui.item.remove();
            },
            start: (event, ui) => {
                const moduleId = $("[data-moduleid]", ui.item).attr("data-moduleid");
                if (moduleId) {
                    this.draggedFormModule = this.questionLibraryModules.find((m) => m.id === moduleId);
                }
            },
            sort: (event, ui) => {
                this.sortLibs$.next({ event, ui });
            },
        });

        $("#modules").sortable({
            connectWith: "#library-modules",
            handle: ".drag-module",
            placeholder: "element-placeholder",
            tolerance: "pointer",
            items: ".module:visible",
            opacity: ".7",
            receive: (event, ui) => {
                if (this.draggedFormModule) {
                    const newPos = ui.item.index();
                    this.dropFormModuleTemplate(this.draggedFormModule, newPos);
                    ui.item.remove();
                    setTimeout(() => this.hackToResetView());
                }

                if (this.draggedNewFormElement) {
                    const newPos = ui.helper.index();
                    this.dropFormElementTemplateWithParentModule("", this.draggedNewFormElement, newPos);
                    ui.helper.remove();
                    setTimeout(() => this.hackToResetView());
                }
            },
            stop: (event, ui) => {
                const order: string[] = $("#modules .module")
                    .toArray()
                    .filter((el) => ($(el).attr("id") ? true : false))
                    .map((el) => $(el).attr("id"));

                this.updateModulesOrder(order);
                this.setupDragDrop();
                this.hackToResetView();
            },
        });

        $(".module").sortable({
            handle: ".drag",
            connectWith: ".module, .lib-module-elements",
            placeholder: "element-placeholder",
            items: ".form-element-container",
            tolerance: "pointer",
            opacity: ".7",
            update: (event, ui) => {
                setTimeout(() => {
                    if (!this.draggedExistingFormElement) {
                        const structure: ElementStructure[] = this.getCurrentStructure();
                        this.changeAndAutoSaveForm(this.form$.value.changeFormElementPosition(structure));
                    }
                });
                this.chRef.detectChanges();
                this.setupDragDrop();
            },
            stop: () => {
                this.draggedExistingFormElement = undefined;
            },
            start: (event, ui) => {
                const id = ui.item.get(0)?.id;
                this.draggedExistingFormElement = this.form$.value.formElementById(id);
            },
            receive: (event, ui) => {
                if (this.draggedNewFormElement) {
                    // drop new form element
                    const newPos = $(`.form-new-element-add`, event.target).index() - 1;
                    const moduleId = event.target.id;
                    this.dropNewFormElement(moduleId, newPos);
                    ui.helper.remove();
                } else if (this.draggedNewFormElementFromTemplate) {
                    // new element from template
                    const newPos = $(`.form-element-add`, event.target).index() - 1;
                    const moduleId = event.target.id;
                    this.dropFormElementInExistingModule(this.draggedNewFormElementFromTemplate, moduleId, newPos);
                    ui.item.remove();
                } else if (this.draggedLibFormElement) {
                    // add element from lib
                    const newPos = $(`.form-element-add`, event.target).index() - 1;
                    const moduleId = event.target.id;
                    this.dropFormElementInExistingModule(this.draggedLibFormElement, moduleId, newPos);
                    ui.item.remove();
                }
                setTimeout(() => this.hackToResetView());
            },
        });

        $("#explain").droppable({
            accept: ".form-element-add, .form-new-element-add, .form-module-add, .module-container, .lib-module-container",
            drop: (event, ui) => {
                if (ui.draggable.hasClass("form-element-add")) {
                    this.dropFormElementTemplateWithParentModule("", this.draggedNewFormElementFromTemplate);
                } else if (ui.draggable.hasClass("module-container") || ui.draggable.hasClass("lib-module-container")) {
                    this.dropFormModuleTemplate(this.draggedFormModule);
                } else if (ui.draggable.hasClass("form-new-element-add")) {
                    this.dropFormElementTemplateWithParentModule("", this.draggedNewFormElement);
                }
                this.setupDragDrop();
            },
        });

        $(".lib-module-elements").sortable({
            connectWith: ".module",
            handle: ".drag-module",
            helper: "clone",
            placeholder: "element-placeholder",
            tolerance: "pointer",
            opacity: ".7",
            receive: (event, ui) => {
                const parent = event.target;
                const index = $(`#${this.draggedExistingFormElement.id}`, parent).index();

                const moduleId = parent.getAttribute("data-moduleid");
                const module = this.questionLibraryModules.find((m) => m.id === moduleId);

                this.editQuestionLibModule.emit(module.addFormElementAtIndex(this.draggedExistingFormElement, index));

                this.draggedExistingFormElement = undefined;
                this.openedModules[module.id] = true;
                this.setupDragDrop();
                this.hackToResetView();
            },
            start: (event, ui) => {
                const id = ui.item.get(0)?.getAttribute("data-formelementid");
                const libFormElement = this.questionLibraryModules
                    .map((m) => m.formElements)
                    .reduce((acc, curVal) => {
                        return acc.concat(curVal);
                    }, [])
                    .find((fe) => fe.id === id);
                if (libFormElement) {
                    this.draggedLibFormElement = libFormElement;
                }
            },
            sort: (event, ui) => {
                this.sortLib$.next({ event, ui });
            },
            stop: () => {
                this.draggedLibFormElement = undefined;
            },
        });

        $(".module-elements").sortable({
            connectWith: ".module",
            handle: ".drag-module",
            helper: "clone",
            placeholder: "element-placeholder",
            tolerance: "pointer",
            opacity: ".7",
            start: (event, ui) => {
                const draggedElement = ui.item;
                const formElementId = draggedElement.attr("data-formelementid");
                const source = draggedElement.attr("data-source");
                if (source && formElementId) {
                    if (source === "library") {
                        const allFormElements = [].concat(...this.questionLibraryModules.map((tm) => tm.formElements));
                        this.draggedNewFormElementFromTemplate = allFormElements
                            .find((fe) => fe.id === formElementId)
                            .change({ id: this.db.createId() });
                    }
                    if (source === "template-elements") {
                        const allFormElements = [].concat(...this.templateModules.map((tm) => tm.formElements));
                        this.draggedNewFormElementFromTemplate = allFormElements
                            .find((fe) => fe.id === formElementId)
                            .change({ id: this.db.createId() });
                    }
                }
            },
            stop: () => {
                this.draggedNewFormElementFromTemplate = undefined;
                this.setupDragDrop();
                this.hackToResetView();
            },
        });

        $(".form-new-element-add").draggable({
            connectToSortable: ".module, #modules",
            helper: "clone",
            start: (event, ui) => {
                this.draggedNewFormElement = this.createInputForElementType(event.target.id as FormElementType);
            },
            stop: (event, ui) => {
                this.draggedNewFormElement = undefined;
            },
        });
    }

    private sortLib(event: any, _: any): void {
        const moduleId = event.target.getAttribute("data-moduleid");

        const module = this.questionLibraryModules.find((m) => m.id === moduleId);
        const newIdsOrder = $("[data-formelementid]", parent)
            .toArray()
            .map((element) => element.getAttribute("data-formelementid"));

        if (newIdsOrder.length === module.formElements.length) {
            this.editQuestionLibModule.emit(
                module.change({
                    formElements: newIdsOrder.map((id) => module.formElements.find((fe) => fe.id === id)),
                })
            );
        }
    }

    private sortLibs(event: any, ui: any): void {
        const reorderedIds = $(".form-library-module-add[data-moduleid]", event.target)
            .toArray()
            .map((element) => element.getAttribute("data-moduleid"));
        if (reorderedIds.length === this.questionLibraryModules.length) {
            this.editQuestionLibModules.emit(
                reorderedIds.map((id) => this.questionLibraryModules.find((m) => m.id === id))
            );
        }
    }

    deleteLibraryModule(moduleId: string): void {
        this.deleteQuestionLibModule.emit(moduleId);
    }

    deleteLibraryFormElement(moduleId: string, formElementId: string): void {
        const module = this.questionLibraryModules.find((m) => m.id === moduleId);
        if (module) {
            this.editQuestionLibModule.emit(module.deleteFormElement(formElementId));
        }
    }

    onLibraryModuleNameChange(event: any, module: FormModule): void {
        const input: HTMLInputElement = event.target;
        const name: TranslatableLabel = { ...module.name };
        name[this.editingLanguage] = input.value;
        this.editQuestionLibModule.emit(module.change({ name }));
    }

    private hackToResetView(): void {
        /* hack to update view */
        /* jquery adds up element in display so we force regenerating the whole edit interface */
        const modules = this.form$.value.modules;
        const templateModules = this.templateModules;
        const library = this.questionLibraryModules;

        // set everything to empty
        this.form$.next(this.form$.value.change({ modules: [] }));
        this.questionLibraryModules = [];
        this.templateModules = [];
        this.chRef.detectChanges();

        // reset the value to their originals
        this.form$.next(this.form$.value.change({ modules }));
        this.templateModules = templateModules;
        this.questionLibraryModules = library;
        this.chRef.detectChanges();
    }

    private getCurrentStructure(): ElementStructure[] {
        const modulesContainer = $("#modules");
        return $(".module", modulesContainer)
            .toArray()
            .map((element) => {
                return {
                    moduleId: element.id,
                    formElementIds: $(".form-element-container", element)
                        .toArray()
                        .map((el) => el.id),
                };
            });
    }

    private dropFormModuleTemplate(formModule: FormModule, position?: number): void {
        const existingModule = this.form$.value.module(formModule.id);
        if (existingModule) {
            this.notificationService.show(
                "Cet élément de formulaire existe déjà dans le formulaire. Il a été réactivé s'il était désactivé.",
                "info"
            );
            this.changeAndAutoSaveForm(this.form$.value.changeModule(existingModule.change({ deleted: false })));
        } else {
            if (position !== undefined) {
                const modules = [...this.form$.value.modules];
                modules.splice(position, 0, formModule);
                this.changeAndAutoSaveData({
                    modules,
                });
            } else {
                this.changeAndAutoSaveData({
                    modules: [...this.form$.value.modules, formModule],
                });
            }
            this.chRef.detectChanges();
        }
    }

    private dropNewFormElement(moduleId: string, newPos: number): void {
        this.changeAndAutoSaveData({
            modules: this.form$.value.modules.map((module) => {
                if (module.id === moduleId) {
                    if (this.draggedNewFormElement) {
                        return module.addFormElementAtIndex(this.draggedNewFormElement, newPos);
                    } else {
                        throw new Error(`Need to implement the formElement strategy : ${this.draggedNewFormElement}`);
                    }
                } else {
                    return module;
                }
            }),
        });
        this.chRef.detectChanges();
    }

    private dropFormElementInExistingModule(formElement: FormElement, moduleId: string, newPos: number): void {
        const existingFe = this.form$.value.formElementByName(formElement.name);
        if (existingFe) {
            this.notificationService.show(
                "Cet élément de formulaire existe déjà dans le formulaire. Il a été réactivé s'il était désactivé.",
                "info"
            );
            this.changeAndAutoSaveForm(this.form$.value.changeFormElement(existingFe.change({ deleted: false })));
        } else {
            this.changeAndAutoSaveData({
                modules: this.form$.value.modules.map((m) => {
                    if (m.id === moduleId) {
                        return m.addFormElementAtIndex(formElement, newPos);
                    }
                    return m;
                }),
            });
        }
        this.chRef.markForCheck();
        this.setupDragDrop();
    }

    private dropFormElementTemplateWithParentModule(moduleName: string, formElement: FormElement, i = 0): void {
        const name: TranslatableLabel = {};
        name[this.editingLanguage] = moduleName;

        const modules = this.form$.value.modules || [];
        modules.splice(i, 0, new FormModule({ id: this.db.createId(), name, formElements: [formElement] }));
        this.changeAndAutoSaveData({
            modules,
        });

        this.chRef.detectChanges();
        this.setupDragDrop();
    }

    private createInputForElementType(elementType: FormElementType): FormElement {
        const createElementStrategy = this._newFormElementStrategy.get(elementType);
        return createElementStrategy().change({ id: this.db.createId() });
    }

    private updateModulesOrder(order: string[]): void {
        this.changeAndAutoSaveData({
            modules: order.map((id) => this.form$.value.modules.find((m) => m.id === id)),
        });
        this.chRef.detectChanges();
    }

    publishModal(): void {
        this.publishPrompt = true;
        this.urlSlug = slugify(this.form$.value.name[this.editingLanguage]);
        this.chRef.detectChanges();
        setTimeout(() => {
            this.publishForm.controls.urlSlug.markAsTouched();
        }, 150);
    }

    cancelPublish(): void {
        this.publishPrompt = false;
    }

    confirmPublish(): void {
        this.addFormLink.emit({
            urlSlug: this.urlSlug,
            form: this.form$.value,
        });
        this.publishPrompt = false;
    }

    addModule(): void {
        this.changeAndAutoSaveData({
            modules: [...this.form$.value.modules, new FormModule({ id: this.db.createId() })],
        });
        this.hackToResetView();
        this.setupDragDrop();
    }

    onFormTitleChanged(title: string, forceLang?: string): void {
        const name = { ...this.form$.value.name };
        name[forceLang ? forceLang : this.editingLanguage] = `${title}`;
        this.changeAndAutoSaveData({ name });
    }

    onFormInternalNameChanged(internalName: string): void {
        this.changeAndAutoSaveData({ internalName });
    }

    copyInput(formElement: FormElement): void {
        if (this.modulesAttributesService.isFieldRestricted(formElement.name)) {
            this.customCopyPaste.customCopy(formElement.toDto());
        } else {
            const elementDto = formElement.toDto();
            const objectToCopy = {
                ...elementDto,
                id: this.db.createId(),
                name: this.db.createId(),
            };
            if (elementDto.options) {
                objectToCopy.options = elementDto.options.map((opt) => {
                    return { ...opt, id: this.db.createId() };
                });
            }
            this.customCopyPaste.customCopy(objectToCopy);
        }
    }

    private pasteFormElement(element: FormElement): void {
        if (element) {
            if (this.form$.value.modules.length > 0) {
                if (this.selectedFormElementId) {
                    this.changeAndAutoSaveData({
                        modules: this.form$.value.modules.map((module, index) => {
                            const selectedFormElementIndex = module.activeFormElements.findIndex(
                                (fe) => fe.id === this.selectedFormElementId
                            );
                            if (selectedFormElementIndex > 0) {
                                return module.addFormElementAtIndex(element, selectedFormElementIndex);
                            }
                            return module;
                        }),
                    });
                } else {
                    this.changeAndAutoSaveData({
                        modules: this.form$.value.modules.map((module, index) => {
                            if (index === 0) {
                                return module.addFormElementAtIndex(element, 0);
                            }
                            return module;
                        }),
                    });
                }
            } else {
                this.dropFormElementTemplateWithParentModule("", element);
            }
            this.chRef.detectChanges();
        }
    }

    panelStateChange(isOpen: boolean): void {
        if (isOpen) {
            this.chRef.detectChanges();
            this.setupDragDrop();
        }
    }

    addQuestionLibMod(formModule?: FormModule): void {
        const copiedFormModule = formModule
            ? formModule.change({ id: this.db.createId() })
            : new FormModule({ id: this.db.createId(), name: { fr: "sans nom" } });

        this.addQuestionLibModule.emit(copiedFormModule);
    }

    openBackupHistory(): void {
        this.openBackup = true;
    }

    onRestore(form: Form): void {
        this.changeAndAutoSaveForm(undefined);
        this.chRef.detectChanges();
        this.changeAndAutoSaveForm(form);
        this.openBackup = undefined;
    }

    ngOnDestroy(): void {
        if (this.isLocked) {
            this.formLockService.unlockForm(this.initForm.id);
        }
        this._disposeBag.dispose();
    }
}

export interface AddFormLinkEvent {
    urlSlug: string;
    form: Form;
}

export interface FormError {
    inputName: string;
    errorType: FormErrorType;
}

export type FormErrorType = "duplicate";

export interface FormStatus {
    edited: boolean;
    lastSave: Date;
    errors?: { [k: string]: FormError };
}

export interface SaveFormEvent {
    form: Form;
    formLockHash: string;
}
