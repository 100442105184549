import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';

import { first, forkJoin, map, of, switchMap } from 'rxjs';
import { selectElementContents } from 'src/app/core/functions';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ClassificationGroup } from 'src/modules/diversite/model/classification-group';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ReportService } from 'src/modules/diversite/services/report.service';
import { PaneReferenceService } from '../../../services/pane-reference.service';

declare var $: any;

@Component({
    selector: 'diversite-classification-group',
    templateUrl: './classification-group.component.html',
    styleUrl: './classification-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassificationGroupComponent {
    @Input() group: ClassificationGroup;
    @Input() defaultGroup: boolean = false;
    @Output() changeGroup = new EventEmitter<ClassificationGroup>();
    @Output() openClassificationStats = new EventEmitter<string>();
    @Output() deleteGroup = new EventEmitter<string>();

    private disposeBag = new DisposeBag();

    toCopyCPs: ContactPerspective[];

    constructor(
        private contactService: ContactService,
        private reportService: ReportService,
        private paneRefService: PaneReferenceService,
        private actionsService: ContextNodePaneActionsService,
        private notificationService: NotificationService,
        private hostElement: ElementRef,
        private chRef: ChangeDetectorRef
    ) { }

    ngAfterViewInit(): void {
        $(`#contacts_${this.group.id}`).sortable({
            connectWith: ".classification-group-sortable",
            forcePlaceholderSize: true,
            helper: "clone",
            placeholder: "classification-group-sortable-box",
            tolerance: "pointer",
            items: "diversite-contact-simple-profile[data-contactid]",

            update: (event, ui) => {
                const contactIds = [...new Set<string>($(`#contacts_${this.group.id} > [data-contactid]`).toArray().map(htmlElement => htmlElement.getAttribute("data-contactid")))];
                $(`#contacts_${this.group.id}`).children(".ui-draggable,.ui-sortable-helper").remove();
                $(`#contacts_${this.group.id}`).sortable("refresh");
                setTimeout(() => {
                    this.changeGroup.emit(this.group.setContacts(contactIds));
                })
            },
        });

    }

    onDeleteGroup(): void {
        return this.deleteGroup.emit(this.group.id);
    }

    onDeleteChildGroup(childGroupId: string): void {
        this.group = this.group.removeChildGroup(childGroupId);
        this.changeGroup.emit(this.group)
    }

    trackByContactId(index: number, contactId: string): string {
        return contactId;
    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    onChangeChildGroup(g: ClassificationGroup): void {
        this.group = this.group.changeChildGroup(g);
        this.changeGroup.emit(this.group)
    }


    addChildGroup(): void {
        this.group = this.group.addChildGroup();
        this.changeGroup.emit(this.group)
    }

    onColorChange(color: string): void {
        this.group = this.group.editColor(color);
        this.changeGroup.emit(this.group)
    }

    onNameChange(name: string): void {
        this.group = this.group.editName(name);
        return this.changeGroup.emit(this.group)
    }

    openMassEditPane(): void {
        this.actionsService
            .openActionPaneAtIndex("mass-edit", this.paneRefService.currentLayout.length + 1, {
                contactIds: this.group.contactIds,
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openMergeMembersPane(): void {
        this.actionsService
            .openActionPaneAtIndex("merge-contacts", this.paneRefService.currentLayout.length + 1, {
                contactIdfigs: [],
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openDownloadPane(): void {
        this.actionsService
            .openActionPaneAtIndex("export", this.paneRefService.currentLayout.length + 1, {
                searchDefinition: SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(this.group.contactIds),
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openSelectionPane(): void {
        this.actionsService.openSearchPane(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(this.group.contactIds)
        ).subscribe().disposedBy(this.disposeBag);
    }

    onViewProfile(cp: ContactPerspective): void {
        this.actionsService.openContactProfilePane(cp.id).subscribe()
            .disposedBy(this.disposeBag);
    }

    openStatsPane(): void {
        this.openClassificationStats.emit(this.group.id)
    }

    onOpenClassificationStats(groupId: string): void {
        this.openClassificationStats.emit(groupId)
    }

    openCreateFolder(): void {
        this.contactService.contactsByIds(this.group.contactIds).pipe(switchMap(contacts => {
            return this.actionsService.openActionPaneAtIndex("add-idfig-list", 1, {
                contactIdfigs: contacts.map(c => c.idfig).filter(idfig => idfig && idfig !== "")
            })
        })).subscribe().disposedBy(this.disposeBag);

    }

    copySelectionInfo(): void {

        forkJoin(
            this.group.contactIds.map((cId) =>
                this.contactService.contactById(cId).pipe(
                    first(),
                    switchMap((c) => {
                        if (c) {
                            return of(c.defaultPerspective);
                        } else {
                            return of(undefined);
                        }
                    }),
                )
            )
        ).pipe(
            map((cps) => cps.filter((cp) => cp))
        ).subscribe((cps: ContactPerspective[]) => {
            this.toCopyCPs = cps;
            this.chRef.detectChanges();
            this.copy();
            this.toCopyCPs = [];
            this.chRef.detectChanges();
        });

    }

    private copy(): void {
        const table = $(".table-for-copy", this.hostElement.nativeElement);
        table.removeClass("hidden");
        selectElementContents(table.get(0));
        navigator.clipboard.writeText(window.getSelection().toString());
        table.addClass("hidden");
        this.notificationService.show(
            "Les informations de l'utilisateur ont été copiés dans le presse-papier.",
            "info"
        );
    }

    createReport(): void {
        this.reportService
            .createReport(this.group.contactIds)
            .pipe(
                switchMap(rId => {
                    return this.paneRefService
                        .addPane(
                            new Pane({
                                type: "report-edit",
                                contextData: { reportId: rId },
                            })
                        )
                })
            )
            .subscribe()
            .disposedBy(this.disposeBag);

    }

    onDelete(contactId: string): void {
        this.group = this.group.toggleContact(contactId);
        this.changeGroup.emit(this.group);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }

}


