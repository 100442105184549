import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter, map, mergeMap, Observable, tap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContactLoadedEvent } from 'src/modules/diversite/components/contacts/contact-search-profile/contact-search-profile.component';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Classification } from 'src/modules/diversite/model/classification';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchResults, SearchResultsGroup, SearchResultsGroupBy } from 'src/modules/diversite/model/search-results';
import { SearchState } from 'src/modules/diversite/model/search-state';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ContactHighlight, ContactResult } from 'src/modules/diversite/services/elasticsearch.service';
import { ReportService } from 'src/modules/diversite/services/report.service';
import { SearchClassificationService } from 'src/modules/diversite/services/search-classification.service';
import { SearchFavoriteNode } from 'src/modules/diversite/services/search-favorite-node.service';
import { SearchProfileVisibleAttributesService } from 'src/modules/diversite/services/search-profile-visible-attributes.service';
import { PaneReferenceService } from '../../../services/pane-reference.service';
import { SearchParametersTab } from '../../search-parameters/search-parameters.component';
import { SelectionPosition } from '../classification-widget/classification-widget.component';
const DEFAULT_AREA_SIZES = [350, "*"];

declare var $: any;

@Component({
    selector: 'diversite-search-contacts-pane-content',
    templateUrl: './search-contacts-pane-content.component.html',
    styleUrl: './search-contacts-pane-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchContactsPaneContentComponent {
    @Input() projectId: string;
    @Input() pane: Pane;
    @Input() contextNodeId: string;
    @Input() loading = false;
    @Input() searchDefinition: SearchDefinition;
    @Input() searchState: SearchState;
    @Input() searchResults: SearchResults = new SearchResults();
    @Input() searchResultsNew: SearchResults = new SearchResults();
    @Input() favoriteSearchNodes: SearchFavoriteNode[];
    @Input() tabOpen: SearchParametersTab = "search";
    @Input() areaSizes = DEFAULT_AREA_SIZES;
    @Input() contactWidth: number = 170;
    @Input() infoSize = 110;
    @Input() groupBy: SearchResultsGroupBy = "no-grouping";

    detailedSearchMetaOpen = false;
    shownAttributesSelection: string[] = [];

    @Output() loadMore = new EventEmitter<SearchState>();
    @Output() changeSearchTab = new EventEmitter<SearchParametersTab>();
    @Output() search = new EventEmitter<SearchEvent>();
    @Output() massEditResults = new EventEmitter<SearchDefinition>();
    @Output() changeContactWidth = new EventEmitter<number>();
    @Output() changeGroupBy = new EventEmitter<SearchResultsGroupBy>();

    @ViewChild("loadingContacts") loadingContacts: ElementRef;

    private disposeBag = new DisposeBag();

    classification$: Observable<Classification>;
    isClassificationPaneOpen$: Observable<boolean>;

    hideSelected = false;

    selectionPosition: SelectionPosition = "bottom";

    constructor(
        private contactsSelectionService: ContactsSelectionService,
        private contactService: ContactService,
        private actionPaneService: ContextNodePaneActionsService,
        private reportService: ReportService,
        private visibleAttributeService: SearchProfileVisibleAttributesService,
        private searchClassificationService: SearchClassificationService,
        private paneRefService: PaneReferenceService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.contactsSelectionService
            .contactsSelection()
            .subscribe((_) => {
                this.chRef.detectChanges();
            })
            .disposedBy(this.disposeBag);
    }



    ngOnChanges(changes: SimpleChanges): void {
        if (changes.areaSizes && !changes.areaSizes.currentValue) {
            this.areaSizes = DEFAULT_AREA_SIZES;
        }
        if (changes.contactWidth) {
            changes.contactWidth.currentValue ? this.contactWidth = changes.contactWidth.currentValue : this.contactWidth = 200;
        }
        if (changes.searchResults?.currentValue) {
            const searchResults: SearchResults = changes.searchResults.currentValue;
            if (searchResults.total.value >= 5000) {
                if (this.searchState.page <= 3) {
                    this.loadMoreContacts();
                }
            } else {
                this.loadMoreContacts();
            }
        }

        if (changes.searchDefinition?.currentValue && !this.classification$) {
            this.classification$ = this.searchClassificationService.classificationForSearch(this.searchDefinition.id);
            this.isClassificationPaneOpen$ = this.paneRefService.layout().pipe(map(layout => {
                return layout.find(p => p.pane?.contextData?.classificationId) ? true : false
            }))
        }
    }

    ngAfterViewInit(): void {

        this.createAndObserve(this.loadingContacts).pipe(
            filter(isVisible => isVisible && !this.loading),
            tap(_ => {
                this.loadMoreContacts()
            })
        ).subscribe().disposedBy(this.disposeBag);
    }

    onDragEndSections(event: any): void {
        console.log(event);

        let sizes;
        if (event.sizes && event.sizes[0] < 30) {
            sizes = [...event.sizes];
            sizes[0] = 30;
        } else {
            sizes = [...event.sizes];
        }
        this.areaSizes = sizes;
        console.log(this.areaSizes);
        this.chRef.detectChanges();
    }

    get isSearchParametersClosed(): boolean {
        return this.areaSizes[0] ? (this.areaSizes[0] as number) <= 30 : false;
    }

    openSearchParams(): void {
        this.areaSizes = DEFAULT_AREA_SIZES;
    }
    closeSearchParams(): void {
        this.areaSizes = [30, "*"];
    }

    changeGroup(group: SearchResultsGroupBy): void {
        if (this.groupBy !== group) {
            this.groupBy = group;
            this.changeGroupBy.emit(group);
        }
    }

    onChangeSelectionPosition(pos: SelectionPosition): void {
        this.selectionPosition = pos;
    }

    onContactLoaded(event: ContactLoadedEvent): void {
        $(event.element.nativeElement).draggable({
            helper: "clone",
            appendTo: "body",
            delay: 150,
            connectToSortable: ".classification-group-sortable"
        });
    }

    private createAndObserve(element: ElementRef): Observable<boolean> {
        if (element?.nativeElement) {
            return new Observable<IntersectionObserverEntry[]>(observer => {
                const intersectionObserver = new IntersectionObserver(entries => {
                    observer.next(entries);
                });

                intersectionObserver.observe(element.nativeElement);

                return () => { intersectionObserver.disconnect(); };
            }).pipe(
                mergeMap((entries: IntersectionObserverEntry[]) => entries),
                map(entry => entry.isIntersecting),
                distinctUntilChanged()
            );
        }
    }

    private loadMoreContacts(): void {
        this.searchState = this.searchState.change({ page: this.searchState.page + 1 });
        this.loadMore.emit(this.searchState)

    }

    contactHeight(contactHighlights: ContactHighlight[]): number {
        if (this.shownAttributesSelection?.length > 0 || contactHighlights?.length > 0) {
            return this.contactWidth * 1.2 + this.infoSize;
        }
        return this.contactWidth * 1.2;
    }

    toggleSelection(contact: Contact): void {
        this.searchClassificationService.toggleSelection(this.searchDefinition.id, contact.id);
    }

    trackByContactResult(_: number, contactData: ContactResult): string {
        return contactData.contact ? contactData.contact.id : contactData.contactId;
    }

    trackByGroup(_: number, group: SearchResultsGroup): string {
        return group.id;
    }

    onChangeTab(tab: SearchParametersTab): void {
        this.changeSearchTab.emit(tab);
    }

    onSearch(searchDefinition: SearchDefinition): void {
        this.loading = true;
        this.searchResults = this.searchResults.change({ contactResults: [] })
        this.search.emit({ searchDefinition, searchState: this.searchState.change({ page: 1 }) });
    }

    onViewProfile(cp: ContactPerspective): void {
        this.actionPaneService.openContactProfilePane(cp.id, this.projectId).subscribe()
            .disposedBy(this.disposeBag);
    }

    onEditContact(contact: Contact): void {
        this.contactService.editContact(contact).subscribe().disposedBy(this.disposeBag);
    }

    onOpenFormPane(formId: string, contactId: string): void {
        this.actionPaneService.openFormPane(formId, this.contextNodeId, this.projectId, contactId);
    }

    isSelected(contactId: string): boolean {
        return this.contactsSelectionService.isSelected(contactId);
    }

    onImageWidthChange(width: any): void {
        this.contactWidth = parseInt(width);
        this.changeContactWidth.emit(this.contactWidth)
    }

    onMassEditResults(searchDefinition: SearchDefinition): void {
        this.massEditResults.emit(searchDefinition);
    }

    onCreateReport(): void {
        this.reportService.createReportForSearch(this.searchDefinition).pipe(
            tap(reportId => {
                return this.actionPaneService.openReportEditPane(reportId, null, this.projectId ? this.projectId : null);
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    onExport(): void {
        return this.actionPaneService.openActionPaneAtIndex("export", 1, { searchDefinition: this.searchDefinition }).subscribe(_ => {
        }).disposedBy(this.disposeBag);
    }

    onCreateFolder(): void {
        return this.actionPaneService.openActionPaneAtIndex("create-folder", 1, { searchDefinition: this.searchDefinition }).subscribe(_ => {
        }).disposedBy(this.disposeBag);
    }

    onComboboxSelectionChange(attributeIds: string[]): void {
        this.visibleAttributeService.setAttributes((attributeIds || []).map(id => {
            return { attributeId: id };
        }));
        this.chRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface SearchEvent {
    searchDefinition: SearchDefinition;
    searchState: SearchState;
}
