import { Injectable } from '@angular/core';

import { BookmarkSpec, DateRange, SearchDefinition } from '../model/search-definition';
import { SearchGroupOperator } from '../model/search-group';
import { SearchGroupMapperService, SearchOperationDTO } from './search-group-mapper.service';
import { Timestamp } from 'firebase/firestore';

@Injectable({
    providedIn: "root",
})
export class SearchDefinitionMapperService {
    constructor(private searchGroupMapper: SearchGroupMapperService) { }

    searchDefinitionToSearchDefinitionDTO(searchDefinition: SearchDefinition): SearchDefinitionDTO {
        const searchDefDto = {
            id: searchDefinition.id,
            bookmarkSpec: searchDefinition.bookmarkSpec,
            projectId: searchDefinition.projectId || null,
            searchGroup: this.searchGroupMapper.searchGroupToSearchGroupDTO(searchDefinition.searchGroup),
            datasource: searchDefinition.datasource || null,
            created: searchDefinition.created ? searchDefinition.created : null
        };
        return searchDefDto;
    }

    searchDefinitionDTOToSearchDefinition(searchDefinitionDTO: SearchDefinitionDTO): SearchDefinition {
        /** Backcompatibility support **/
        const data = searchDefinitionDTO as any;
        if (data.searchOperations) {
            searchDefinitionDTO.searchGroup = { operator: "AND", searchOperations: data.searchOperations };
        }
        /** End compatibility support */
        const searchDef: SearchDefinition = new SearchDefinition({
            id: searchDefinitionDTO.id,
            bookmarkSpec: data.bookmarkSpec ? this.mapBookmarkSpec(data.bookmarkSpec) : null,
            projectId: searchDefinitionDTO.projectId,
            searchGroup: this.searchGroupMapper.searchGroupDTOToSearchGroup(searchDefinitionDTO.searchGroup),
            datasource: searchDefinitionDTO.datasource,
            created: searchDefinitionDTO.created ? (searchDefinitionDTO.created as Timestamp).toDate() : null
        });

        return searchDef;
    }


    private mapBookmarkSpec(bookmarkSpec: any): BookmarkSpec {
        return { ...bookmarkSpec, bookmark: { ...bookmarkSpec.bookmark, date: bookmarkSpec.bookmark.date ? bookmarkSpec.bookmark.date.toDate() : null } }
    }
}

export interface SearchDefinitionDTO {
    id?: string;
    bookmarkSpec?: BookmarkSpec;
    projectId?: string;
    searchGroup?: SearchGroupDTO;
    availabilities?: DateRange[];
    datasource?: string;
    created?: Timestamp | Date;
}

export interface SearchGroupDTO {
    id?: string;
    fullTextSearch?: SearchOperationDTO;
    operator: SearchGroupOperator;
    searchGroups?: SearchGroupDTO[];
    searchOperations?: SearchOperationDTO[];
    disabled?: boolean;
}
