<div class="sub-groups-container">
    <div class="group" *ngFor="let subGroup of statisticGroup.subCategoryGroups; trackBy: trackById">
        <strong>{{ subGroup.name }} {{ subGroup.percentage }}%</strong>
        <div *ngIf="!subGroup.categoryGroup" class="contacts">
            <diversite-contact-simple-profile
                *ngFor="let contact of subGroup.contacts; trackBy: trackById"
                [contact]="contact"
                [style.width.px]="cardSize"
                [style.height.px]="cardSize"
                (viewProfile)="onOpenProfile($event)"
            />
        </div>

        <diversite-statistic-group *ngIf="subGroup.categoryGroup" [statisticGroup]="subGroup.categoryGroup" />
    </div>
</div>
