import { Contact } from "./contact";
import { StatisticCategoryGroup } from "./statistic-category-group";

export class StatisticSubCategoryGroup {
    private _id: string;
    private _name: string;
    private _contacts: Contact[];
    private _percentage: number;
    private _categoryGroup: StatisticCategoryGroup;

    constructor(builder: { id: string, name: string, contacts: Contact[], percentage: number, statisticCategoryGroup: StatisticCategoryGroup }) {
        this._id = builder.id;
        this._name = builder.name;
        this._contacts = builder.contacts;
        this._percentage = builder.percentage;
        this._categoryGroup = builder.statisticCategoryGroup || null;
    }

    get id(): string { return this._id; }
    get name(): string { return this._name; }
    get contacts(): Contact[] { return this._contacts; }
    get percentage(): number { return this._percentage; }
    get categoryGroup(): StatisticCategoryGroup { return this._categoryGroup; }
}
