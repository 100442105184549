<ng-container *ngIf="form$ | async; let form">
    <div *ngIf="!isLocked && formLockChecked" class="alert alert-info" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
                </div>
                <span class="alert-text">Ce formulaire est vérouillé.</span>
            </div>
        </div>
    </div>

    <div class="top">
        <!-- {{ form.id }} -->
        <input
            (change)="onFormTitleChanged($event.target.value)"
            class="edit-name"
            type="text"
            placeholder="Nom publique"
            [value]="form.name[editingLanguage] || ''"
        />
        <input
            (change)="onFormInternalNameChanged($event.target.value)"
            class="edit-name"
            type="text"
            placeholder="Nom interne"
            [value]="form.internalName"
        />
    </div>
    <as-split [unit]="'pixel'">
        <as-split-area [order]="0" [size]="260" class="col-left">
            <div *ngIf="formActive" class="modules">
                <small
                    class="save-status"
                    [class.warning]="saveStatus.edited && !saveStatus.errors"
                    [class.success]="!saveStatus.edited && !saveStatus.errors"
                    [class.errors]="saveStatus.errors"
                >
                    <cds-icon [shape]="saveStatus.edited ? 'note' : 'check-circle'"></cds-icon>

                    <span [hidden]="saveStatus.errors">{{ saveStatus.edited ? "Modifié" : "Sauvegardé" }}</span>

                    <span [hidden]="!saveStatus.errors">Le formulaire ne peut pas être sauvegardé.</span>
                    <span [hidden]="!saveStatus.lastSave">
                        <br />
                        Dernière sauvegarde : {{ saveStatus.lastSave | date : "dd-MM-yyyy - HH:mm" }}</span
                    >
                </small>

                <cds-divider></cds-divider>
                <small>{{ form.isPublished ? "Publié" : "Non-publié" }}</small>
                <cds-divider></cds-divider>

                <div class="urls">
                    <div *ngFor="let context of publicContexts; trackBySlug" class="url-container">
                        <input
                            [disabled]="context.disabled"
                            (click)="copyUrl($event)"
                            class="url"
                            type="text"
                            readonly
                            [value]="url(context)"
                        />

                        <a
                            *ngIf="!context.disabled"
                            role="tooltip"
                            aria-haspopup="true"
                            class="tooltip tooltip-sm tooltip-top-left"
                        >
                            <cds-icon (click)="disableLink(context)" shape="ban"></cds-icon>
                            <span class="tooltip-content">Désactiver</span>
                        </a>
                        <a
                            *ngIf="context.disabled"
                            role="tooltip"
                            aria-haspopup="true"
                            class="tooltip tooltip-sm tooltip-top-left"
                        >
                            <cds-icon (click)="enableLink(context)" shape="upload-cloud"></cds-icon>
                            <span class="tooltip-content">Réactiver</span>
                        </a>
                        <a
                            *ngIf="context.disabled"
                            role="tooltip"
                            aria-haspopup="true"
                            class="tooltip tooltip-sm tooltip-top-left"
                        >
                            <cds-icon (click)="deleteLink(context)" shape="times"></cds-icon>
                            <span class="tooltip-content">Effacer</span>
                        </a>
                    </div>
                </div>

                <div class="actions">
                    <ng-container *ngIf="!form.isTemplate">
                        <a class="action-link" (click)="publishModal()">Publier un nouveau lien</a>
                        <a class="action-link" (click)="saveACopy()">Faire une copie</a>
                        <!-- <a class="action-link" (click)="saveAsTemplate()">Sauvegarder comme modèle</a> -->
                        <a class="action-link" (click)="previewMode()">Voir le formulaire</a>
                    </ng-container>
                    <a
                        (click)="openRespondentsPane()"
                        class="action-link"
                        [class.disabled]="!publicContexts || publicContexts.length < 1"
                    >
                        Voir les répondant(s)
                    </a>
                    <a class="action-link" [class.disabled]="form.languages.length < 2" (click)="openTranslateModal()">
                        Traduire
                    </a>
                </div>
                <cds-divider></cds-divider>

                <cds-toggle>
                    <label>Fr</label>
                    <input
                        [disabled]="hasLanguage('fr') && form.languages.length == 1"
                        (change)="toggleLanguage('fr')"
                        type="checkbox"
                        [checked]="hasLanguage('fr')"
                    />
                </cds-toggle>

                <cds-toggle>
                    <label>En</label>
                    <input
                        [disabled]="hasLanguage('en') && form.languages.length == 1"
                        (change)="toggleLanguage('en')"
                        type="checkbox"
                        [checked]="hasLanguage('en')"
                    />
                </cds-toggle>

                <cds-divider></cds-divider>

                <cds-radio-group *ngIf="form.languages.length > 0" layout="vertical">
                    <cds-radio *ngIf="form.hasLanguage('fr')">
                        <label>Francais</label>
                        <input
                            name="fr"
                            (click)="setCurrentEditingLanguage('fr', true)"
                            type="radio"
                            [checked]="editingLanguage === 'fr'"
                        />
                    </cds-radio>

                    <cds-radio *ngIf="form.hasLanguage('en')">
                        <label>Anglais</label>
                        <input
                            name="en"
                            (click)="setCurrentEditingLanguage('en', true)"
                            type="radio"
                            [checked]="editingLanguage === 'en'"
                        />
                    </cds-radio>
                </cds-radio-group>
                <br />
                <clr-accordion [clrAccordionMultiPanel]="true">
                    <clr-accordion-panel
                        [(clrAccordionPanelOpen)]="panelTemplateOpen"
                        (clrAccordionPanelOpenChange)="panelStateChange($event)"
                    >
                        <clr-accordion-title>Modules</clr-accordion-title>
                        <clr-accordion-content *clrIfExpanded>
                            <div id="template-modules">
                                <div
                                    class="module-container"
                                    *ngFor="let module of templateModules; trackBy: trackByFnModules"
                                >
                                    <div [attr.data-moduleid]="module.id" class="module-name btn-key form-module-add">
                                        <cds-icon class="drag-module" shape="drag-handle"></cds-icon>
                                        <clr-icon
                                            size="14"
                                            (click)="changeOpenState(module.id)"
                                            shape="angle"
                                            [attr.dir]="isOpen(module.id) ? 'down' : 'right'"
                                        ></clr-icon>
                                        <div>{{ module.name[editingLanguage] }}</div>
                                    </div>

                                    <div class="module-elements">
                                        <div
                                            [hidden]="!isOpen(module.id)"
                                            data-source="template-elements"
                                            [attr.data-formelementid]="formElement.id"
                                            class="form-element-add"
                                            *ngFor="let formElement of module.formElements"
                                        >
                                            <cds-icon class="drag-module" shape="drag-handle"></cds-icon>
                                            {{ label(formElement) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </clr-accordion-content>
                    </clr-accordion-panel>
                    <clr-accordion-panel
                        [(clrAccordionPanelOpen)]="panelLibOpen"
                        (clrAccordionPanelOpenChange)="panelStateChange($event)"
                    >
                        <clr-accordion-title>Librairie</clr-accordion-title>
                        <clr-accordion-content>
                            <div id="library-modules">
                                <div
                                    class="lib-module-container"
                                    *ngFor="let module of questionLibraryModules; trackBy: trackByFnModules"
                                >
                                    <div
                                        [attr.data-moduleid]="module.id"
                                        class="module-name btn-key form-library-module-add"
                                    >
                                        <cds-icon class="drag-module" shape="drag-handle"></cds-icon>
                                        <clr-icon
                                            size="14"
                                            (click)="changeOpenState(module.id)"
                                            shape="angle"
                                            [attr.dir]="isOpen(module.id) ? 'down' : 'right'"
                                        ></clr-icon>

                                        <input
                                            class="edit-module-name-input"
                                            type="text"
                                            [value]="module.name[editingLanguage]"
                                            (change)="onLibraryModuleNameChange($event, module)"
                                        />

                                        <cds-icon
                                            (click)="deleteLibraryModule(module.id)"
                                            class="trash-icon"
                                            shape="trash"
                                        ></cds-icon>
                                    </div>

                                    <div [attr.data-moduleid]="module.id" class="lib-module-elements">
                                        <div
                                            [hidden]="!isOpen(module.id)"
                                            data-source="library"
                                            [attr.data-formelementid]="formElement.id"
                                            class="form-element-add"
                                            *ngFor="let formElement of module.activeFormElements; trackBy: trackById"
                                        >
                                            <cds-icon class="drag-module" shape="drag-handle"></cds-icon>
                                            {{ label(formElement) }}
                                            <cds-icon
                                                (click)="deleteLibraryFormElement(module.id, formElement.id)"
                                                shape="trash"
                                                class="trash-icon"
                                            ></cds-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button (click)="addQuestionLibMod()" class="btn btn-sm">Ajouté un groupe</button>
                        </clr-accordion-content>
                    </clr-accordion-panel>
                    <clr-accordion-panel
                        [(clrAccordionPanelOpen)]="panelInputOpen"
                        (clrAccordionPanelOpenChange)="panelStateChange($event)"
                    >
                        <clr-accordion-title>Elements</clr-accordion-title>
                        <clr-accordion-content *clrIfExpanded>
                            <div id="elements-list">
                                <div id="textbox" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Texte
                                </div>
                                <div id="textarea" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Texte (long)
                                </div>
                                <div id="number" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Nombre
                                </div>
                                <div id="datetime" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Date
                                </div>
                                <div id="radio" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Radio
                                </div>
                                <div id="checkbox" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Case à cocher
                                </div>
                                <div id="dropdown" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Menu déroulant
                                </div>
                                <div id="union" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Union
                                </div>
                                <div id="phone" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Téléphone
                                </div>
                                <div id="height" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Grandeur
                                </div>
                                <div id="weight" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Poids
                                </div>
                                <div id="imageupload" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Image(s)
                                </div>
                                <div id="videoupload" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Vidéo(s)
                                </div>
                                <div id="fileupload" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Fichier(s)
                                </div>
                                <div id="availability" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Disponibilité
                                </div>

                                <div id="text" class="btn-key form-new-element-add">
                                    <clr-icon shape="drag-handle"></clr-icon> Texte statique
                                </div>
                            </div>
                        </clr-accordion-content>
                    </clr-accordion-panel>
                    <clr-accordion-panel
                        *ngIf="form.isTemplate"
                        [(clrAccordionPanelOpen)]="panelVariablesOpen"
                        (clrAccordionPanelOpenChange)="panelStateChange($event)"
                    >
                        <clr-accordion-title>Variables</clr-accordion-title>
                        <clr-accordion-content *clrIfExpanded>
                            <table
                                *ngIf="form.variables.length > 0"
                                class="variable-table table table-compact table-noborder"
                            >
                                <tr *ngFor="let v of form.variables; trackBy: trackById">
                                    <td class="left">{{ v.name }}</td>
                                    <td class="left">
                                        <input class="variable-slug" type="text" disabled [value]="v.slug" />
                                    </td>
                                    <td class="left">
                                        <cds-icon (click)="removeVariable(v.id)" [shape]="'times'"></cds-icon>
                                    </td>
                                </tr>
                            </table>
                            <button (click)="addVariablePrompt()" class="btn btn-sm">Ajouter une variable</button>
                        </clr-accordion-content>
                    </clr-accordion-panel>
                </clr-accordion>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox [(ngModel)]="showDeleted" />
                    <label>Montrer les elements cachée</label>
                </clr-checkbox-wrapper>
                <small (click)="openBackupHistory()" class="action-link">Sauvegarde passée(s)</small>
            </div>
        </as-split-area>
        <as-split-area [size]="'*'" class="col-content">
            <div class="contents">
                <div class="form-elements">
                    <div class="scroll-content">
                        <div class="logo-section">
                            <div><img class="logo" [src]="form.logo" /></div>
                            <div class="clr-file-wrapper">
                                <label class="clr-control-label">
                                    <span class="btn btn-primary btn-sm">Téléverser une image pour le formulaire</span>
                                    <input
                                        [disabled]="logoUploading"
                                        (change)="onLogoInputChange($event)"
                                        class="clr-file"
                                        type="file"
                                        accept="image/*"
                                        name="form-logo-upload"
                                    />
                                </label>
                            </div>
                            <input
                                [placeholder]="'Logo'"
                                class="input-logo"
                                name="logo"
                                [value]="form.logo"
                                (change)="changeFormLogo($event.target.value)"
                            />
                        </div>
                        <div id="explain" class="explain" [hidden]="form.modules.length !== 0">
                            Veuillez ajouter des éléments de formulaire via le menu de gauche en les glissant-déposant
                            ici.
                        </div>
                        <div id="modules" class="content">
                            <div
                                *ngFor="let module of form.modules; trackBy: trackByFnModules; let i = index"
                                class="module"
                                [id]="module.id"
                                [hidden]="module.deleted && !showDeleted"
                                [class.deleted]="module.deleted"
                                [class.mandatory]="module.isMandatoryValidationModule"
                            >
                                <div class="module-header">
                                    <cds-icon
                                        [hidden]="module.deleted || module.isMandatoryValidationModule"
                                        (click)="deleteModule(module.id)"
                                        class="delete-module"
                                        shape="times"
                                        size="20"
                                    >
                                    </cds-icon>
                                    <cds-icon
                                        [hidden]="!module.deleted || module.isMandatoryValidationModule"
                                        (click)="reactivateModule(module.id)"
                                        class="activate-form-element"
                                        shape="plus"
                                        size="16"
                                    ></cds-icon>
                                    <cds-icon
                                        [hidden]="module.isMandatoryValidationModule"
                                        class="drag-module"
                                        shape="drag-handle"
                                        size="28"
                                    >
                                    </cds-icon>
                                    <input
                                        class="edit-module-name-input"
                                        type="text"
                                        [value]="module.name[editingLanguage] || ''"
                                        (change)="onModuleNameChange($event, module)"
                                    />
                                </div>
                                <ng-container
                                    *ngFor="let formElement of module.formElements; trackBy: trackByFnElement"
                                >
                                    <div
                                        [hidden]="formElement.deleted && !showDeleted"
                                        [id]="formElement.id"
                                        (click)="selectFormElement(formElement)"
                                        (clickOutside)="deselectFormElement()"
                                        class="form-element-container"
                                        [class.selected]="formElement.id === selectedFormElementId"
                                        [class.deleted]="formElement.deleted"
                                        [class.duplicate]="saveStatus.errors && saveStatus.errors[formElement.name]"
                                    >
                                        <div class="form-element-options">
                                            <cds-icon
                                                [hidden]="formElement.deleted || module.isMandatoryValidationModule"
                                                (click)="deleteFormElement(module.id, formElement.id)"
                                                class="delete-form-element"
                                                shape="times"
                                                size="16"
                                            ></cds-icon>
                                            <cds-icon
                                                [hidden]="!formElement.deleted || module.isMandatoryValidationModule"
                                                (click)="reactivateFormElement(module.id, formElement.id)"
                                                class="activate-form-element"
                                                shape="plus"
                                                size="16"
                                            ></cds-icon>
                                            <cds-icon
                                                [hidden]="module.isMandatoryValidationModule"
                                                class="drag"
                                                shape="drag-handle"
                                                size="22"
                                            ></cds-icon>
                                            <cds-icon
                                                (click)="copyInput(formElement)"
                                                shape="copy-to-clipboard"
                                                size="14"
                                            ></cds-icon>
                                        </div>
                                        <diversite-form-element-edit
                                            *ngIf="formElement && editingLanguage"
                                            [lang]="editingLanguage"
                                            [formElement]="formElement"
                                            (formElementChanged)="onFormElementChange($event, module)"
                                        >
                                        </diversite-form-element-edit>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="add-btn">
                            <button
                                *ngIf="form.modules.length !== 0"
                                class="btn btn-sm btn-primary"
                                (click)="addModule()"
                            >
                                Ajouter un module
                            </button>
                        </div>
                        <div class="thank-you-container">
                            <div class="thank-you">
                                <div class="thank-you-header">Texte de remerciements/confirmation</div>
                                <div class="clr-form-control thank-you-text">
                                    <diversite-text-html-editor
                                        [contentHtml]="endText[editingLanguage]"
                                        (contentChange)="onEndTextChanged($event)"
                                    ></diversite-text-html-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </as-split-area>
    </as-split>

    <clr-modal
        *ngIf="translateModalOpen"
        [(clrModalOpen)]="translateModalOpen"
        [clrModalSize]="'xl'"
        (clrModalOpenChange)="onTranslateModalOpenChange($event)"
    >
        <h3 class="modal-title">Traduire</h3>
        <div class="modal-body">
            <table class="table table-compact">
                <thead>
                    <tr>
                        <th class="left" *ngFor="let lang of form.languages">{{ lang }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let lang of form.languages" class="left">
                            <input
                                (change)="onFormTitleChanged($event.target.value, lang)"
                                class="input-translate title"
                                type="text"
                                [placeholder]=""
                                [value]="form.name[lang] || ''"
                            />
                        </td>
                    </tr>
                    <tr *ngFor="let formElement of form.activeFormElements(); trackBy: trackByFnElement">
                        <td class="left" *ngFor="let lang of form.languages">
                            <ng-container *ngIf="formElement.isInput()">
                                <input
                                    (change)="onInputTranslateChange($event.target.value, formElement, lang)"
                                    class="input-translate"
                                    type="text"
                                    [value]="labelFormLang(formElement, lang)"
                                />

                                <div *ngIf="formElement.options && formElement.options.length > 0">
                                    <div class="option" *ngFor="let option of formElement.options; trackBy: trackById">
                                        <input
                                            (change)="
                                                onInputOptionTranslateChange(
                                                    $event.target.value,
                                                    formElement,
                                                    option,
                                                    lang
                                                )
                                            "
                                            class="input-translate option"
                                            type="text"
                                            [value]="option.label[lang]"
                                        />
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formElement.text">
                                <diversite-text-html-editor
                                    [contentHtml]="labelFormLang(formElement, lang)"
                                    (contentChange)="onTextTranslateChanged($event, formElement, lang)"
                                ></diversite-text-html-editor>
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td *ngFor="let lang of form.languages" class="left">
                            <diversite-text-html-editor
                                [contentHtml]="form.endText[lang]"
                                (contentChange)="onEndTextChanged($event, lang)"
                            ></diversite-text-html-editor>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-sm" (click)="onTranslateModalOpenChange(false)">Fermer</button>
        </div>
    </clr-modal>

    <clr-modal *ngIf="publishPrompt" [(clrModalOpen)]="publishPrompt">
        <h3 class="modal-title">Publication</h3>
        <div class="modal-body">
            <form #publishForm>
                <clr-input-container>
                    <label>Lien du formulaire</label>
                    <input
                        name="disabled-url-part"
                        [size]="urlFirstPart.length"
                        class="url-input"
                        clrInput
                        disabled
                        [value]="urlFirstPart"
                    />
                    <input
                        publicContextAsyncValidator
                        class="slug-input"
                        placeholder="Spécifier un url désiré..."
                        clrInput
                        [(ngModel)]="urlSlug"
                        name="urlSlug"
                        slug
                        required
                    />
                    <clr-control-error *clrIfError="'invalidSlug'"
                        >* Le lien est invalide. Seulement les tirets et lettres minuscules sont
                        permises.</clr-control-error
                    >
                    <clr-control-error *clrIfError="'required'">* Ce champs est requis</clr-control-error>
                    <clr-control-error *clrIfError="'alreadyExist'"
                        >* Un fomulaire avec le même lien existe déjà
                    </clr-control-error>
                    <clr-control-helper>Ce lien sera le lien publique pour accèder au formulaire.</clr-control-helper>
                </clr-input-container>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" (click)="cancelPublish()">Canceller</button>
                    <button
                        [disabled]="!publishValid()"
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="confirmPublish()"
                    >
                        Publier
                    </button>
                </div>
            </form>
        </div>
    </clr-modal>

    <clr-modal *ngIf="openBackup" [(clrModalOpen)]="openBackup" [clrModalSize]="'xl'">
        <h3 class="modal-title">Sauvegarde</h3>
        <div class="modal-body">
            <diversite-form-backups [formId]="initForm.id" (restore)="onRestore($event)"></diversite-form-backups>
        </div>
    </clr-modal>

    <clr-modal *ngIf="openAddVariablePrompt" [(clrModalOpen)]="openAddVariablePrompt">
        <h3 class="modal-title">Ajouter une variable</h3>
        <div class="modal-body">
            <clr-input-container>
                <label>Nom de la variable</label>
                <input clrInput name="variable-name" [(ngModel)]="variableName" />
            </clr-input-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm" (click)="cancelAddVariable()">Canceller</button>
            <button type="button" class="btn btn-primary btn-sm" (click)="addVariable()">Ajouter la variable</button>
        </div>
    </clr-modal>
</ng-container>
