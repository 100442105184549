<div *ngIf="classificationGroup$ | async as group" class="classification-stats-container">
    <div class="top">
        <strong>{{ group.name }}</strong>
        <!-- <clr-range-container [clrRangeHasProgress]="true">
            <input type="range" clrRange min="60" max="300" [(ngModel)]="cardSize" />
        </clr-range-container> -->
    </div>
    <div class="stats-container">
        <div class="stat-selector">
            <ul class="ordered-category">
                <li [attr.data-categoryid]="categoryId" *ngFor="let categoryId of selectedCategories$ | async">
                    <cds-icon shape="drag-handle" />{{ category(categoryId)?.name
                    }}<cds-icon (click)="onRemoveCategoryGroup(categoryId)" class="delete" shape="times" />
                </li>
            </ul>

            <clr-select-container>
                <label>Choisir une catégorie</label>
                <select [(ngModel)]="selectedCategory" clrSelect>
                    <option value="">-</option>
                    <option [value]="category.id" *ngFor="let category of availableStatsCategories; trackBy: trackById">
                        {{ category.name }}
                    </option>
                </select>
            </clr-select-container>
            <button (click)="addCategory()" class="btn btn-sm btn-icon"><cds-icon shape="plus"></cds-icon></button>
        </div>
        <div *ngIf="mainStatisticGroup$ | async as mainGroup" class="classification-stats">
            <diversite-statistic-group [cardSize]="cardSize" [statisticGroup]="mainGroup" />
        </div>
    </div>
</div>
